import {

  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

type ContextProviderProps = {
  children: React.ReactNode;
};

interface ContextITFC {
 
 
  mainCollection: string;
  setMainCollection: (mainCollection: string) => void;

}
export const AppContext = createContext<ContextITFC>({


  mainCollection: "",
  setMainCollection: (mainCollection: string) => {},


});

export const AppContextProvider = ({ children }: ContextProviderProps) => {

  const [mainCollection, setMainCollection] = useState<string>("all");



  return (
    <AppContext.Provider
      value={{
        mainCollection,
        setMainCollection,
    

      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useAppState() {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useGlobalState must be used within a GloabalProvider");
  }
  return context;
}
