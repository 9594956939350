import { useAppState } from "Constexts/app.context";
import assets from "assets";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";

const SwitchBtn = () => {
  const { mainCollection, setMainCollection } = useAppState();
  const navigate = useNavigate();
  const { nftId } = useParams();

  useEffect(() => {
    if (mainCollection === "aps") {
      navigate("/ape");
    } else if (mainCollection === "donsy") {
      navigate("/donsy");
    } else if (mainCollection === "all") {
      navigate("/");
    }
  }, [mainCollection]);

  return (
    <Wrapper>
      <div className="subHead">
        {/* <div className="logoplace">
          <img src={assets.logos.logok} alt="" />
        </div> */}

        <div>
          <div className="switches-container">
            {/* New radio input for All */}
            <input
              type="radio"
              id="switchAll"
              name="switchPlan"
              value="all"
              checked={mainCollection === "all"}
              onChange={(e: any) => {
                setMainCollection(e.target.value);
              }}
            />

            <input
              type="radio"
              id="switchMonthly"
              name="switchPlan"
              value="aps"
              checked={mainCollection === "aps"}
              // checked={true}

              onChange={(e: any) => {
                setMainCollection(e.target.value);
              }}
            />
            <input
              type="radio"
              id="switchYearly"
              name="switchPlan"
              value="donsy"
              checked={mainCollection === "donsy"}
              onChange={(e: any) => {
                setMainCollection(e.target.value);
              }}
            />
            {/* New label for All */}
            <label htmlFor="switchAll">All</label>
            <label htmlFor="switchMonthly">0xPolygonApe</label>
            <label htmlFor="switchYearly">DonsyNFT</label>
            <div className="switch-wrapper">
              <div className="switch">
                <div>All</div> {/* New label in switch */}
                <div>0xPolygonApe</div>
                <div>DonsyNFT</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .subHead {
    display: flex;
    /* margin-bottom: 20px; */
    gap: 27px;
    justify-content: start;
  }
  .logoplace {
    /* width: 100%; */
  }
  .switches-container {
    width: 23rem;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    border-radius: 4px;
    border: 0.5px solid #c4c4c4;
    background: linear-gradient(180deg, #f5f4f4 0%, #fff 100%);
    line-height: 3rem;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
  }

  /* input (radio) for toggling. hidden - use labels for clicking on */
  .switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
  }

  /* labels for the input (radio) boxes - something to click on */
  .switches-container label {
    width: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: rgba(206, 206, 206, 1);
    white-space: nowrap;
  }

  /* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
*/
  .switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 33.33%;
    padding: 0.15rem;
    z-index: 3;
    transition: all 0.75s cubic-bezier(0.77, 0, 0.175, 1);
    /* transition: transform 1s; */
  }

  /* switch box highlighter */
  .switch {
    border-radius: 4px;
    background: rgba(0, 0, 0, 1);
    height: 100%;
  }

  /* switch box labels
    - default setup
    - toggle afterwards based on radio:checked status 
*/
  .switch div {
    white-space: nowrap;
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: #ffffff;
    transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
  .subHead {
    display: flex;
    // margin-bottom: 20px;
    gap: 27px;
    justify-content: space-between;
  }
       .switches-container {
    width: 15rem;
    line-height: 2rem;


       }
       label{
    font-size: 12px;


       }
       .switch{
    font-size: 12px;


       }
       .logoplace{
  width: 100px;
  img{
    width: 100%;
  }
}
    `}
  /* slide the switch box from right to left */
  .switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
    transform: translateX(0%);
  }
  /* slide the switch box to the 'Polygon Ape' position */
  .switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
    transform: translateX(100%);
  }
  /* slide the switch box from left to right */
  .switches-container input:nth-of-type(3):checked ~ .switch-wrapper {
    transform: translateX(200%);
  }

  /* toggle the switch box labels - first checkbox:checked - show first switch div */
  .switches-container
    input:nth-of-type(1):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(1) {
    opacity: 1;
  }

  /* toggle the switch box labels - second checkbox:checked - show second switch div */
  .switches-container
    input:nth-of-type(2):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(2) {
    opacity: 1;
  }
  .switches-container
    input:nth-of-type(3):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(3) {
    opacity: 1;
  }
`;
export default SwitchBtn;
