import assets from "assets";
import { GenericImageWrapper } from "components/Logo";
import Row, { RowBetween, RowFixed } from "components/Row";
import React from "react";
import { ChevronDown, ChevronLeft, ChevronRight } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";

export const MONTHS_NAMES: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

export const VIEW_YEARS: string = 'YEARS';
export const VIEW_MONTHS: string = 'MONTHS';



export interface IProps {
    year: void | number;
    month: void | number;
    startYear?: number;
    className?: string;
    onChange?: (selectedYear: number, selectedMonth: number) => void;
    onClose?: () => void;
    isMobileView: boolean;
}

export interface IState {
    years: Array<number>;
    selectedYear: void | number;
    selectedMonth: void | number;
    currentView: string;
    isOpen: boolean;
}

export default class YearMonthPickerComponent extends React.Component<IProps, IState> {
    wrapperRef: any;
    constructor(props: IProps) {
        super(props);

        const { year, month } = this.props;

        const startYear: number = this.props.startYear || new Date().getFullYear() - 6;

        this.state = {
            years: Array.from({ length: 12 }, (_v, k) => k + startYear),
            selectedYear: year,
            selectedMonth: month,
            currentView: year ? VIEW_MONTHS : VIEW_YEARS,
            isOpen: false,
        };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside(event: any) {
        if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target) && this.state.isOpen) {
            this.onClose();
        }
    }

    componentWillReceiveProps(nextProps: { year: any; month: any; }) {
        const { year, month } = nextProps;
        const { selectedYear, selectedMonth } = this.state;

        if (typeof year === 'number' &&
            typeof month === 'number' &&
            (year !== selectedYear || month !== selectedMonth)
        ) {
            this.setState({
                selectedYear: year,
                selectedMonth: month,
                currentView: VIEW_MONTHS
            });
        }
    }

    onChange = (selectedYear: number | void, selectedMonth: number | void): void => {
        if (typeof selectedYear === 'number' && typeof selectedMonth === 'number') {
            this.props.onChange && this.props.onChange(selectedYear, selectedMonth);
        }
    }

    onOpen = (): void => {
        this.setState({
            isOpen: true
        });
    }

    onClose = (): void => {
        this.setState({
            isOpen: false
        });
        this.props.onClose && this.props.onClose();
    }

    selectYear = (selectedYear: number): void => {
        this.setState({ selectedYear, currentView: VIEW_MONTHS });
        this.onChange(selectedYear, this.state.selectedMonth);
    }

    selectMonth = (selectedMonth: number): void => {
        this.setState({ selectedMonth });
        this.onChange(this.state.selectedYear, selectedMonth);
        this.onClose();
    }

    previous = (): void => {
        const startYear = this.state.years[0] - 12;
        this.updateYears(startYear);
    }

    next = (): void => {
        const startYear = this.state.years[11] + 1;
        this.updateYears(startYear);
    }

    updateYears = (startYear: number): void => {
        const years = Array.from({ length: 12 }, (_v, k) => k + startYear);

        this.setState({ years, currentView: VIEW_YEARS });
    }

    isYears = (): boolean => {
        return this.state.currentView === VIEW_YEARS;
    }

    renderMonths = (): JSX.Element[] => {
        const { selectedMonth } = this.state;

        return MONTHS_NAMES.map((month, index) => {

            return (
                <Items
                    selected={selectedMonth === index}
                    key={index}
                    onClick={() => this.selectMonth(index)}
                >{month}</Items>
            );
        });
    }

    renderYears = (): JSX.Element[] => {
        const { selectedYear } = this.state;

        return this.state.years.map((year, index) => {

            return (
                <Items
                    selected={selectedYear === year}
                    key={index}
                    onClick={() => this.selectYear(year)}
                >{year}</Items>
            );
        });
    }

    renderHead = ({ onValueClick, onNext, onPrev }: { onValueClick: any, onNext: any, onPrev: any }): JSX.Element => {
        const { selectedYear, selectedMonth } = this.state;
        const isYearView = this.isYears();
        const years = this.state.years;

        function selectedValue(): JSX.Element {
            if (isYearView) {
                return <TEXT.Body> {years[0]}-{years[years.length - 1]}</TEXT.Body>
            } else {
                return <TEXT.Body>{(typeof selectedYear === 'number') ? selectedYear : "-"}</TEXT.Body>
            }
            // const monthVal = selectedMonth < 10 ? '0' + selectedMonth : selectedMonth;
            // return monthVal + '/' + selectedYear;
        }

        return (
            <HeaderWrapper>
                <RowBetween width="100%">
                    <Row gap="6px"
                        onClick={onValueClick}
                    >
                        {selectedValue()} <ChevronDown width={16} height={16} />
                    </Row>

                    <RowFixed gap="6px">
                        <ChevronLeft onClick={onPrev} width={24} height={24} />
                        <ChevronRight onClick={onNext} width={24} height={24} />
                    </RowFixed>
                </RowBetween>
            </HeaderWrapper>
        );
    }

    render(): JSX.Element {
        const { isOpen } = this.state;
        const { isMobileView } = this.props;
        return (
            <Container ref={this.wrapperRef}>
                <GenericImageWrapper
                    onClick={() => isOpen ? this.onClose() : this.onOpen()}
                    src={assets.icons.calendarIcon}
                    size={isMobileView ? "14px" : "16px"}
                />

                {isOpen && (
                    <FlyOut >
                        <Wrapper>
                            <div
                                className="ryms-clickout"
                                onClick={this.props.onClose}
                            />
                            {/* <this.Head
                                year={selectedYear}
                                month={selectedMonth ? selectedMonth + 1 : undefined}
                            /> */}

                            {
                                this.renderHead({
                                    onValueClick: () => this.setState({ currentView: VIEW_YEARS }),
                                    onPrev: this.previous,
                                    onNext: this.next
                                })
                            }
                            <div>
                                {this.isYears() ? this.renderYears() : this.renderMonths()}
                            </div>
                        </Wrapper>
                    </FlyOut>
                )}
            </Container>
        );
    }
}

const Container = styled.div`
  position: relative;
    z-index: 10;
`;
const Wrapper = styled.div`
 display: flex;
 flex-direction: column;
 gap:12px;
`;

const FlyOut = styled.div`
  background: ${({ theme }) => theme.bgBase};
  border: 0.5px solid ${({ theme }) => theme.border};
  border-radius: 16px;
  position: absolute;
  top: 50px;
  right: 10px;
  z-index: 10;
  padding: 10px 12px;
  width: 260px;
  max-height: 410px;
  overflow:auto;
  ${({ theme }) => theme.mediaWidth.upToMedium`
     max-height: 280px;
     overflow:auto;
  `}
`;



const Items = styled.div<{ selected: boolean }>`
  width: 33.33%;
  display:table;
  float:left;
  text-align: center;
  height: fit-content;
  padding: 6px 16px;
  background-color:${({ theme, selected }) => selected ? theme.primary : "transparent"};
  border-radius:50px;
  margin:8px 0px;
  color: black !important;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
   border-radius: 201px;
  `}
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0px 16px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  `}
`;

// import React, { useState } from 'react';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import DatePicker from "react-date-picker";
// import YearMonthPicker from 'react-year-month-picker'
// //import DateTimePicker from 'react-datetime-picker';

// export default function YearMonthPickerComponent() {
//     const [value, onChange] = useState(new Date());

//     return (
//         <div>
//             <YearMonthPicker
//                 onChange={onChange} value={value}
//             // dateFormat="MMMM yyyy"
//             />
//         </div>
//     );
// }