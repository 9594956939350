import axios from "axios";
import ContentCardCtr from "components/ContentCard";
import {
  CircledImageCtr,
  GenericImageWrapper,
  SnapshotBrandLogoImageWrapper,
} from "components/Logo";
import { useCallback, useEffect, useState } from "react";
import { AlertCircle, Percent } from "react-feather";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import QuestionHelper from "components/QuestionHelper";
import { formatAmount } from "utils/numbers";
import GainLossPercentComponent from "../../../../components/Percent";
import { TEXT } from "theme/texts";
import { useTopTrendingCategories } from "data/nft/topTrendingCategories";
import { SquareIconButton } from "components/Button";
import assets from "assets";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import { useRef } from "react";
import Spacer from "components/Spacer";
import { IconWrapper } from "theme/components";
import { useCurrencyUnit } from "store/user/hooks";
import NewLoader from "components/Loader/NewLoader";
import { isMobile } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SKLTCollectionCard from "components/Skeletons/CollectionsPage/SKLTCollectionCard";
import { getChainTypeLogo } from "utils/logo";
import { ChainType } from "types";

import { utils, providers } from "ethers";
import { useAppState } from "Constexts/app.context";

const EntityCard = ({ card, index, type }) => {
  const { currency } = useCurrencyUnit();
  const navigate = useNavigate();
  // approx_profitability_matic
  let volume =
    currency?.symbol === "MATIC"
      ? card?.volume_matic_bought
      : currency?.symbol === "ETH"
      ? card?.volume_eth_bought
      : card?.volume_usd_bought;
  let price =
    currency?.symbol === "MATIC"
      ? card?.price_in_matic
      : currency?.symbol === "ETH"
      ? card?.price_in_eth
      : card?.price_in_usd;
  const renderCategoryLogo = (card) => {
    switch (card?.name?.toLowerCase()) {
      case "entertainment / media":
        return assets.icons.entertainmentIcon;
      case "gaming":
        return assets.icons.gameIcon;
      case "utility":
        return assets.icons.utilIcon;
      case "art":
        return assets.icons.artIcon;
      case "defi":
        return assets.icons.defiIcon;
      case "unknown":
      case "misc":
        return assets.icons.miscxIcon;
      case "pfp":
        return assets.icons.userIcon;
      case "brands":
        return assets.icons.likeIcon;
      default:
        return card?.logo || assets.logos.twitterWhiteLogo;
    }
  };
  return (
    <EntityCardCtr>
      <div className="_cardLeft">
        <div className="entityCred">
          <p className="entityName">
            {card?.ensName
              ? card?.ensName
              : card?.address?.slice(0, 6) + "..." + card?.address?.slice(-4)}
            <span>
              {card?.username && (
                <img
                  src={assets.logos.twitterWhiteLogo}
                  alt={card?.username}
                  style={{ width: "15px", height: "15px", marginLeft: "5px" }}
                  onClick={() =>
                    window.open(
                      `https://twitter.com/${card?.username}`,
                      "_blank"
                    )
                  }
                />
              )}
            </span>
          </p>

          {card?.token_id ? (
            <p className="entityVol">
              #
              {card?.token_id?.length > 5
                ? card?.token_id?.slice(0, 5) + "..."
                : card?.token_id}
            </p>
          ) : (
            <p className="entityVol">#{index}</p>
          )}
        </div>
      </div>

      {!card?.sales_count && (
        <div className="_cardRight">
          <p className="entityValue">
            {type === "profit" && (
              <CircledImageCtr size="15px">
                <img
                  src={currency.logo}
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </CircledImageCtr>
            )}
            {type === "profit"
              ? formatAmount(volume, 2)
              : type === "held"
              ? formatAmount(card?.nfts_held_currently, 0)
              : type === "sold"
              ? formatAmount(card?.count_sold, 0)
              : formatAmount(card?.count_bought, 0)}
          </p>

          {/* <p
          className={
            card?.price_change > 0
              ? "success_status _entityPercent"
              : "warning_status _entityPercent"
          }
        >
          {card?.price_change > 0 ? (
            <span>
              {"+"}
              {card?.price_change}
            </span>
          ) : (
            <span>{card?.price_change}</span>
          )}
        </p> */}
          {/* <GainLossPercentComponent value={card?.price_change} fontSize=".8rem" /> */}
        </div>
      )}
    </EntityCardCtr>
  );
};

const CollectionCard = ({ card, index }) => {
  const [collectionContent, setCollectionContent] = useState(null);
  const [timeframe, setTimeframe] = useState(0); //0-> 1hr, 1-> 1day, 2-> alltime
  const totalTrendingCategory = useTopTrendingCategories();
  const refCard = useRef(null);
  const snapshotDownloadIconId = `snapshot-${index}-download-icon`;
  const snapshotBrandLogoId = `snapshot-${index}-brand-logo`;

  console.log(snapshotDownloadIconId, snapshotBrandLogoId);
  const downloadScreenshot = useTakeScreenshot({
    ref: refCard,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });
  const FetchENSName = async (address) => {
    try {
      const provider = new providers.JsonRpcProvider(
        "https://rpc.ankr.com/eth"
      );
      const ensName = await provider.lookupAddress(address);
      return ensName;
    } catch (error) {
      return address?.slice(0, 6) + "..." + address?.slice(-4);
    }
  };
  const { mainCollection, setMainCollection } = useAppState();

  let profileID ; 
  
  if(mainCollection === "aps"){
   profileID = '0x419e82D502f598Ca63d821D3bBD8dFEFAf9Bbc8D';


  } else if(mainCollection === "donsy"){
   profileID = '0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB';


  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const fetchCollectionContent = useCallback(async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/ape/traderboard`);
    let flattenedArray = await res?.data?.records?.flat(Infinity);
    flattenedArray = await Promise.all(flattenedArray);

    let data = await flattenedArray?.filter((i)=> i.contract_address === profileID?.toLowerCase()).map(async (item, index) => {
      // const ensName = await FetchENSName(item?.address);
      return { ...item };
    });

    const dataq = await Promise.all(data);
 

    if (card.type === "profit") {
      const sortedResults = dataq
        .filter((item) => item.volume_eth_bought !== null)
        .sort(
          (a, b) => b.volume_eth_bought - a.volume_eth_bought
        );
      data = sortedResults;
  
    }
    if (card.type === "sold") {
      const sortedResults = dataq
        .filter((item) => item.count_sold !== null)
        .sort((a, b) => b.count_sold - a.count_sold);
      data = sortedResults;
 
    }
    if (card.type === "bought") {
      const sortedResults = dataq
        .filter((item) => item.count_bought !== null)
        .sort((a, b) => b.count_bought - a.count_bought);
      data = sortedResults;
 
    }
    if (card.type === "held") {
      // .filter((item) => item.nfts_held_currently !== null)

      const sortedResults = dataq
        .sort((a, b) => b.nfts_held_currently - a.nfts_held_currently);
      data = sortedResults;
    
    }
    // console.log(data, "datanew");
    setCollectionContent(data);
  }, [timeframe, mainCollection]);
  console.log(collectionContent);
  // const [itemIdx, setItemIdx] = useState(1);

  useEffect(() => {
    // if(itemIdx <= 10){
    //   setItemIdx(itemIdx+1)
    // }
    
    fetchCollectionContent(timeframe);
  }, [fetchCollectionContent, mainCollection]);
  return (
    <div ref={refCard}>
      <CollectionCardCtr>
        <div className="_cardHeader">
          {/* <div className="_cardIcons">
          <div className="_cardNav" id="_cardNavCollectionCard">
            {card?.options?.map((item, idx) => (
              <button
                onClick={() => setTimeframe(idx)}
                className={timeframe === idx ? "activeCardTab" : null}
              >
                {item}
              </button>
            ))}
          </div>
          <GenericImageWrapper
            id={snapshotDownloadIconId}
            className="_cardDownloadIcon"
            onClick={() => downloadScreenshot()}
            src={assets.icons.roundedDownloadIcon}
            size="2rem"
          />
          <SnapshotBrandLogoImageWrapper
            id={snapshotBrandLogoId}
            src={assets.logos.surfaceboardXYZLogo}
            size="6rem"
            style={{ marginRight: isMobile ? "8px" : "10px" }}
          />
        </div> */}
          <div className="_cardTitle">
            <TEXT.SmallHeader>
              {card.title}
              <QuestionHelper text={card.toolTip} />
            </TEXT.SmallHeader>
          </div>
        </div>
        <div className="_cardResults">
          {collectionContent?.length > 0 ? (
            collectionContent?.map((res, idx) => (
              <EntityCard
                card={res}
                index={idx + 1}
                key={idx}
                type={card?.type}
              />
            ))
          ) : (
            <SKLTCollectionCard cards={5} />
          )}
        </div>
      </CollectionCardCtr>
    </div>
  );
};

const CollectionCardCtr = styled(ContentCardCtr)`
  --padding-x: 1rem;
  --padding-y: 0.5rem;
  gap: 0;
  ${(props) => props.theme.flexColNoWrap}
  overflow-y: auto;
  gap: 1rem;
  ._cardHeader {
    ${(props) => props.theme.flexColNoWrap}

    ._cardTitle {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding-top: 1rem;
      padding: var(--padding-x);
      border: 1px solid ${(props) => props.theme.disabled};
      border-right: 0;
      border-left: 0;
      font-family: var(--ff-subtitle);
    }
    ._cardIcons {
      padding: var(--padding-x);
      display: flex;
      align-items: center;
      justify-content: space-between;

      ._cardNav {
        border: 1px solid ${(props) => props.theme.disabled};
        background: ${(props) => props.theme.disabledVar2};
        width: fit-content;
        border-radius: 0.25rem;
        button {
          background: none;
          min-width: 70px;
          padding: 0.25rem 0.5rem;
          border: none;
          border-radius: 0.15rem;
          color: ${(props) => props.theme.body};
        }
      }
    }
  }

  ._cardResults {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    height: 350px;
    padding: var(--padding-y) var(--padding-x);
    overflow: hidden;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  //@media quries : mobile screens
  ${({ theme }) => theme.mediaWidth.upToMedium`
  min-width:320px;
  `}
`;
const EntityCardCtr = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  height: fit-content;

  ._cardRight {
    text-align: right;
    .entityValue {
      display: flex;
      align-items: center;
      font-family: var(--ff-title);
      margin: 0;
      padding: 0;
      gap: 0.15rem;
    }
    ._entityPercent {
      font-size: var(--fs-s);
      margin: 0;
      padding-top: 0.15rem;
    }
  }
  ._cardLeft {
    ${(props) => props.theme.flexRowNoWrap}
    align-items: center;
    gap: 0.5rem;
    flex: 1;
    overflow: hidden;
    .entityIcon {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .entityName {
      display: flex;
      gap: 0.5rem;
      font-family: var(--ff-title);
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
      padding: 0;
    }
    .entityVol {
      font-size: var(--fs-s);
      margin: 0;
      padding-top: 0.15rem;
    }
  }
`;
export default CollectionCard;
