import axios from "axios";
import { useMemo, useState } from "react";

interface api {
  loading: boolean;
  error: boolean;
  data: any;
}

const getData = async (profileID: string) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL1}/aggregations/liveActivity?contractAddress=${profileID}`

      //   "https://nftsurfaceboard.up.railway.app/api/aggregations/liveActivity?contractAddress=0xace8187b113a38f83bd9c896c6878b175c234dcc"
    );
    return data;
  } catch (error) {
    return error;
  }
};

export function usePolygonLiveActivity(profileID: string) {
  const [api, setAPIData] = useState<api>({
    loading: true,
    error: false,
    data: [] || undefined,
  });

  useMemo(async () => {
    const data = await getData(profileID);
    setAPIData({
      loading: false,
      error: false,
      data: data,
    });
  }, []);
  return api;
}
