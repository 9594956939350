import assets from "assets";
import { SmallOptionButton } from "components/Button";
import BarChart from "components/Chart/BarChart";
import LineChart from "components/Chart/LineChart";
import { AutoColumn } from "components/Column";
import { HorizontalDivider } from "components/Divider/inde";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { GenericImageWrapper } from "components/Logo";
import { TabularNumsText } from "components/shared";
import { useTotalNFTMint } from "data/nft/totalNFTMint";
import dayjs from "dayjs";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { TEXT } from "theme/texts";
import { ChainType, GenericChartSeries, TimeWindow } from "types";
import getTimewindowFilteredData from "utils/chart";
import { formatAmount } from "utils/numbers";
import {
  appRoutes,
  getScrollToElementPath,
  marketOverviewPageElementIDs,
} from "utils/path";
import {
  ChartTopRightSectionWrapper,
  ChartWrapper,
  ListButtonWrapper,
} from "../styled";
import { useUniqueWallets } from "data/yoots/UniqueWallets";
import Row from "components/Row";
import { getChainTypeLogo } from "utils/logo";
import { useZettaUniqueWallets } from "data/yoots/zetta/UniqueWallets";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAppState } from "Constexts/app.context";

const twitterShareScrollPath = getScrollToElementPath(
  appRoutes.marketOverview,
  marketOverviewPageElementIDs.nftMints
);
const snapshotDownloadIconId = "snapshot-nft-mints-download-icon";
const snapshotBrandLogoId = "snapshot-nft-mints-brand-logo";

const TopRightComponent = ({
  timeWindow,
  setTimeWindow,
  isMobileView,
}: {
  timeWindow: TimeWindow;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
  isMobileView: boolean;
}) => {
  return (
    <ChartTopRightSectionWrapper>
      <ListButtonWrapper>
        {[
          TimeWindow.threeMonth,
          TimeWindow.sixMonth,
          TimeWindow.oneYear,
          TimeWindow.all,
        ].map((option, index) => {
          return (
            <SmallOptionButton
              key={index}
              active={timeWindow === option}
              onClick={() => setTimeWindow(option)}
            >
              {option === "custom" ? (
                <GenericImageWrapper
                  src={assets.icons.calendarIcon}
                  size={isMobileView ? "14px" : "16px"}
                />
              ) : (
                option
              )}
            </SmallOptionButton>
          );
        })}
      </ListButtonWrapper>
    </ChartTopRightSectionWrapper>
  );
};

const TopLeftComponent = ({ count }: { count: number }) => {
  return (
    <AutoColumn gap="4px">
      <TEXT.LargeHeader>
        <TabularNumsText>
          {`${formatAmount(count).toLocaleUpperCase()} Wallets`}
        </TabularNumsText>
      </TEXT.LargeHeader>
    </AutoColumn>
  );
};

export default function UniqueWalletsGraph({
  refGraph,
}: {
  refGraph: React.RefObject<HTMLElement>;
}) {
  const theme = useTheme();
  const uniqueOwners = useUniqueWallets();
  const [volumeHover, setVolumeHover] = useState<number | undefined>();
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
  const [leftLabel, setLeftLabel] = useState<string | undefined>();
  const [rightLabel, setRightLabel] = useState<string | undefined>();
  const [timeWindow, setTimeWindow] = useState(TimeWindow.oneYear);
  const totalNFTMints = useTotalNFTMint();
  const reverseTotalNFTMints = [...(totalNFTMints.records ?? [])].reverse();
  const { isMobile } = useMatchBreakpoints();
  const [showBarGraph, setShowBarGraph] = useState(true);
  const [chainType, setChainType] = useState(ChainType.polygon);
  const [ownersCountGraph, setOwnersCountGraph] = useState<any>();
//   const profileID = "0x419e82D502f598Ca63d821D3bBD8dFEFAf9Bbc8D";

const { mainCollection, setMainCollection } = useAppState();
  



  let profileID: any;

  if (mainCollection === "aps") {
    profileID = "0x419e82D502f598Ca63d821D3bBD8dFEFAf9Bbc8D";
  } else if (mainCollection === "donsy") {
    profileID = "0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB";
  }
  
  const fetchOwnersCountGraph = async () => {
    try {
      const fetchData = await axios.get(
        `${process.env.REACT_APP_API_URL}/profileData/owners/${profileID}?duration=2`,
        {
          timeout: 10000,
        }
      );

      //           const fetchData = await axios.get('https://polygon-rest.api.mnemonichq.com/collections/v1beta2/0xace8187b113a38f83bd9c896c6878b175c234dcc/owners_count/DURATION_3_DAYS/GROUP_BY_PERIOD_1_HOUR', {
      //   headers: {
      //     'X-API-Key': 'c1MlzA1fohGWoGOojRAKmtnhkiNYq1PCvYu1Da3ksbMbvzQJ'
      //   }
      // });

      let data = fetchData?.data?.data?.map((item: any) => {
        return {
          time: new Date(item?.timestamp).getTime(),
          wallets: Number(item?.count),
        };
      });
      // group data by days by taking only two data points from each day
      // data = data?.reduce((acc: any, item: any, index: number) => {
      // if (index % 6 === 0) {
      //     acc.push(item);
      // }
      // return acc;
      // }, []);
      // group time by 6 hours and don't sum up the wallets

      setOwnersCountGraph(data);
      console.log(data, ownersCountGraph);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadScreenshot = useTakeScreenshot({
    ref: refGraph,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });

  const [reload, setReload] = useState(1)

  useEffect(() => {
    if(reload <= 2){
        setReload(reload +1)
    }
    setLiquidityHover(undefined);
    setVolumeHover(undefined);
    fetchOwnersCountGraph();
  }, [reload,mainCollection]);

//   const PolygonData = useZettaUniqueWallets();
//   let PolygonGraphData =
//     PolygonData?.records?.[0]?.date_time &&
//     PolygonData?.records?.map((data: any, index: number) => {
//       return {
//         id: index,
//         time: new Date(data.date_time)?.getTime(),
//         wallets: data.cumulative_unique_wallets,
//       };
//     });
//   // reverse data for graph

//   PolygonGraphData = PolygonGraphData?.reverse();
  /**
   * @notice
   * @dev 'formattedVolumeData' function format data according to graph plot format
   * @dev 'getTransformedVolumeData' function filter chart data according to selected timewindow
   * @dev 'formattedChartSeries' change according to requirement
   * @param timewindow is selected option out of provided time options to user for filtering chart data
   * @param time will be for x-axis
   * @param value... must be same as @param datakey of config variable 'formattedChartSeries'
   */
  const formattedChartData = useMemo(() => {
    // if (totalNFTMints && totalNFTMints.records) {
    //     return getTimewindowFilteredData(totalNFTMints.records, timeWindow).map(
    //         (record) => {
    //             return {
    //                 time: record.dateTime,
    //                 valueNFTMint: record.countMints,
    //             };
    //         }
    //     );
    if (chainType === ChainType.polygon && ownersCountGraph) {
      return ownersCountGraph?.map((data: any) => {
        return {
          time: new Date(data.time).getTime(),
          wallets: data.wallets,
        };
      });
    } else if (
      chainType === ChainType.sol &&
      uniqueOwners &&
      uniqueOwners.data?.data?.data
    ) {
      return uniqueOwners.data.data.data?.map((data: any, index: number) => {
        return {
          id: index,
          time: data.x,
          wallets: data.y?.owners,
        };
      });
    } else {
      return [];
    }
  }, [uniqueOwners?.data, chainType, ownersCountGraph]);

  /**
   * @notice
   * @dev 'formattedChartSeries'  must change according to requirement
   * Add according requirement (This will plot line graph with provided color and data)
   */
  const formattedChartSeries: GenericChartSeries[] = [
    { dataKey: "wallets", name: "wallets", color: theme.graphLines },
  ];

  const filteredTotalCount: number = useMemo(() => {
    if (reverseTotalNFTMints.length === 0) {
      return 0;
    }
    switch (timeWindow) {
      case TimeWindow.threeMonth: {
        const beforeThreeMonthData = reverseTotalNFTMints.find((element) =>
          dayjs(element.dateTime).isBefore(
            dayjs().subtract(3, "month"),
            "month"
          )
        );
        return (
          reverseTotalNFTMints[0].cumulativeMints -
          (beforeThreeMonthData?.cumulativeMints ?? 0)
        );
      }
      case TimeWindow.sixMonth: {
        const beforeSixMonthData = reverseTotalNFTMints.find((element) =>
          dayjs(element.dateTime).isBefore(
            dayjs().subtract(6, "month"),
            "month"
          )
        );
        return (
          reverseTotalNFTMints[0].cumulativeMints -
          (beforeSixMonthData?.cumulativeMints ?? 0)
        );
      }
      case TimeWindow.oneYear: {
        const beforeOneYearData = reverseTotalNFTMints.find((element) =>
          dayjs(element.dateTime).isBefore(dayjs().subtract(1, "year"), "month")
        );
        return (
          reverseTotalNFTMints[0].cumulativeMints -
          (beforeOneYearData?.cumulativeMints ?? 0)
        );
      }
      case TimeWindow.all:
        return reverseTotalNFTMints[0].cumulativeMints;
      default:
        return reverseTotalNFTMints[0].cumulativeMints;
    }
  }, [totalNFTMints, timeWindow]);

  return (
    <>
      <GraphTableTopHeader
        title={"Unique Wallets"}
        tooltip="Number of wallets holding atleast 1 NFT from the collection"
        showBarGraph={true}
        downloadScreenshot={downloadScreenshot}
        isMobileView={isMobile}
        snapshotBrandLogoId={snapshotBrandLogoId}
        snapshotDownloadIconId={snapshotDownloadIconId}
        tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights:  @LayerEhq here: ${twitterShareScrollPath} `}
      />
      <HorizontalDivider />
      {showBarGraph ? (
        <ChartWrapper>
          <BarChart
            data={formattedChartData}
            series={formattedChartSeries}
            yAxisLabel="Wallets"
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={timeWindow}
            topRight={
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <TopLeftComponent
                    count={
                      chainType === ChainType?.polygon
                        ? ownersCountGraph &&
                          ownersCountGraph[ownersCountGraph?.length - 1]
                            ?.wallets
                        : uniqueOwners?.data?.data?.data &&
                          uniqueOwners?.data?.data?.data[
                            uniqueOwners?.data?.data?.data?.length - 1
                          ]?.y?.owners
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "100%",
                  }}
                ></div>
              </div>
            }
          />
        </ChartWrapper>
      ) : (
        <ChartWrapper>
          <LineChart
            data={formattedChartData}
            series={formattedChartSeries}
            yAxisLabel="Wallets"
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={timeWindow}

            // topRight={
            //     <TopRightComponent
            //         timeWindow={timeWindow}
            //         setTimeWindow={setTimeWindow}
            //         isMobileView={isMobile}
            //     />
            // }
            // topLeft={<TopLeftComponent count={filteredTotalCount} />}
          />
        </ChartWrapper>
      )}
    </>
  );
}
