export const ENDPOINT_CLIENT = "https://api.thegraph.com/subgraphs/name/";
export const ENDPOINT_BLOCK_CLIENT =
  "https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks";
export const ENDPOINT_health_CLIENT = "";
export const ENDPOINT_MEDIAN_AVG_MATIC_PRICE =
  "https://api.zettablock.com/api/v1/dataset/sq_f81bc1da660a4a3a9cbe7f9c19d32922/graphql";
export const ENDPOINT_MATIC_CURRENT_PRICE =
  "https://api.zettablock.com/api/v1/dataset/sq_3d9b043cc90642dab0259608ea4b69fa/graphql";

export const TOTAL_NFT_MINT =
  "https://api.zettablock.com/api/v1/dataset/sq_860da65ea37a4ad7a9b27cdf043403c6/graphql";

export const TOTAL_DAILY_NFT_BUYER =
  "https://api.zettablock.com/api/v1/dataset/sq_e34f77723b594e02ad946ab9b37adee2/graphql";
export const TOTAL_DAILY_CONTRACT_INTERACTIONS =
  "https://api.zettablock.com/api/v1/dataset/sq_372543dc74f44e9b9c15fbd7f0a2c0df/graphql";
export const TOTAL_DAILY_NFT_SELLER =
  "https://api.zettablock.com/api/v1/dataset/sq_c0f069dd425b4778a4cf53589236211f/graphql";
export const TOTAL_UNIQUE_OWNERS =
  "https://api.zettablock.com/api/v1/dataset/sq_e573d918815d4604ad20634fd79955ae/graphql";

export const TOTAL_NFT_COLLECTIONS =
  "https://api.zettablock.com/api/v1/dataset/sq_f5bd36682df74ba1a5be550e5058159a/graphql";
export const TOTAL_NFT_WALLETS =
  "https://api.zettablock.com/api/v1/dataset/sq_27f8331357bb4ac4b340441e35509d2b/graphql";
export const TOP_TRENDING_CATEGORIES =
  "https://api.zettablock.com/api/v1/dataset/sq_497579f562604438b02e7d9b5e1a796a/graphql";
export const TOTAL_NFT_VOLUME_AND_SALES =
  "https://api.zettablock.com/api/v1/dataset/sq_f1926a7db43c4997ad247b0c924713b6/graphql";
export const NFT_SOLD =
  "https://api.zettablock.com/api/v1/dataset/sq_d31bbe42f129423495b9cf3d570d1fdd/graphql";

export const COLLECTION_MINTED_TODAY =
  "https://api.zettablock.com/api/v1/dataset/sq_c39b14df8bc24a32b036bf93cd181f75/graphql";
export const TOP_MARKETPLACE =
  "https://api.zettablock.com/api/v1/dataset/sq_52a335bdb6af47feb06df39c29e8a388/graphql";
export const NFT_MARKET_CAP =
  "https://api.zettablock.com/api/v1/dataset/sq_75af2952e1ce4935ba5ce55b190946d8/graphql";

export const y00ts_Stats =
  "https://api.zettablock.com/api/v1/dataset/sq_48e77ec77f164a1f8e16064d04ad53f6/graphql";

export const y00ts_Trading_Data =
  "https://api.zettablock.com/api/v1/dataset/sq_a16f4cb8b1184e4d99b65824a7126418/graphql";
export const y00ts_Wash_trades =
  "https://api.zettablock.com/api/v1/dataset/sq_200863c8f78c4f4a86a4e96747ed69a6/graphql";
export const y00ts_Unique_Wallets =
  "https://api.zettablock.com/api/v1/dataset/sq_9e08158b68424f14a71853d3052b2cdb/graphql";

export const y00ts_Hold_Duration =
  "https://api.zettablock.com/api/v1/dataset/sq_d453b0199a1246489a9ec4fdc3683b12/graphql";
export const y00ts_Mutual_Collections =
  "https://api.zettablock.com/api/v1/dataset/sq_cc9764b9dc7647d39eaddc947d2d79a4/graphql";

export const y00ts_Mutual_Tokens =
  "https://api.zettablock.com/api/v1/dataset/sq_a5baf11c662d4f379c8c84831613b503/graphql";
export const y00ts_Activity =
  "https://api.zettablock.com/api/v1/dataset/sq_8df4e9c2d4e948069729f417762ab502/graphql";

export const y00ts_Sales =
  "https://api.zettablock.com/api/v1/dataset/sq_dba5dc5cb51a47f48a68353a1b776fea/graphql";
export const y00ts_Bridging =
  "https://api.zettablock.com/api/v1/dataset/sq_355dfb44a1514f7483556cc3b9a178e4/graphql";
