import assets from "assets";
import { SquareIconButton } from "components/Button";
import Column, { AutoColumn } from "components/Column";
import { OutlinedLabel } from "components/DataCard";
import { CircledImageCtr, GenericImageWrapper } from "components/Logo";
import Row from "components/Row";
import { Copy, Globe } from "react-feather";
import styled from "styled-components";
import { colors } from "theme/colors";
import { TEXT } from "theme/texts";
import SocialLinks from "./SocialLinks";
import CustomPieChart from "components/Chart/PieChart";
import { copyTextToClipboard } from "utils/clipboardCopy";
import { useZettaCollectionStats } from "data/yoots/zetta/collectionStats";
import { useSolanaStats } from "data/yoots/y00tsStats";
import { useZettaBridging } from "data/yoots/zetta/BridgingCount";
import axios from "axios";
import { useEffect, useState } from "react";
import { usePolygonListing } from "data/yoots/PolygonListing";
import { ethers, utils, providers } from "ethers";
import { ABI, ADDRESS } from "data/yoots/zetta/polygoneApe";
import { useParams } from "react-router-dom";
import { useAppState } from "Constexts/app.context";
import { ABIDonsy, ADDRESSDonsy } from "data/yoots/zetta/donsy";

//to generate ellipses in middle of address text
function trimAddress(str) {
  if (str.length > 12) {
    return str.substr(0, 5) + "..." + str.substr(str.length - 4, str.length);
  }
  return str;
}

const ProfileStats = () => {
  const PolygonData = useZettaCollectionStats();
  const solanaData = useSolanaStats();
  const bridged = useZettaBridging();
  const [staked, setStaked] = useState(false);
  const [loan, setLoan] = useState(false);

  const { mainCollection, setMainCollection } = useAppState();

  const FetchOwners = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://polygon-rpc.com"
      );
      let contract;
      let balance;
      let Loan;
      if (mainCollection === "aps") {
        contract = new ethers.Contract(ADDRESS, ABI, provider);
        balance = await contract.balanceOf(
          "0x55B7a60e33773a83D4C2Fa5F52e6eB0440BF5C26"
        );
        Loan = await contract.balanceOf(
          "0x872b753fB8E2efe28Cf8C0Df149BCca1916687C7"
        );
      } else if (mainCollection === "donsy") {
        // profileID = '0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB';
        contract = new ethers.Contract(ADDRESSDonsy, ABIDonsy, provider);
        balance = await contract.balanceOf(
          "0x55B7a60e33773a83D4C2Fa5F52e6eB0440BF5C26"
        );
        Loan = await contract.balanceOf(
          "0x2C8Be2D93Ca7f278358D8eE872388622aB0Ad683"
        );
      }else{

      }

      console.log(balance);
      setStaked(Number(balance._hex));
      setLoan(Number(Loan._hex));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    FetchOwners();
  }, [staked, mainCollection]);

  return (
    <ProfileStatsCtr>
      {staked && (
        <CustomPieChart
          height={100}
          style={{ width: "clamp(80px, 10vw,100px)" }}
          data={[
            {
              name: "Polygon",
              value:
                (PolygonData?.records &&
                  PolygonData?.records[0]?.circulating_supply -
                    (staked && staked)) - (loan && loan) || 0,
              fill: "#9E77ED",
            },
            { name: "Solana", value: parseInt(staked), fill: "#DD8C98" },
            { name: "Solana", value: parseInt(loan), fill: "#800000" },
          ]}
          series={[]}
        />
      )}
      <Column
        style={{
          gap: ".5rem",
        }}
        className="_chartLabels"
      >
        <Row gap=".5rem">
          <svg
            width="clamp(.8rem,2vw,1.35rem)"
            height="clamp(.8rem,2vw,1.35rem)"
            viewBox="0 0 10 10"
            fill="none"
            style={{ flexShrink: "0" }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5" cy="5" r="5" fill="#9E77ED" />
          </svg>
          <TEXT.MediumHeader
            className="_chartValue"
            style={{
              color: "#000000",
            }}
          >
            {(PolygonData?.records &&
              PolygonData?.records[0]?.circulating_supply) -
              (staked && staked) -
              (loan && loan) || 0}
          </TEXT.MediumHeader>
          <TEXT.Disabled
            className="_chartLabel"
            style={{
              color: "#919191",
            }}
          >
            Remaining Unstaked NFTs
          </TEXT.Disabled>
        </Row>
        <Row gap=".5rem" width={"max-content"}>
          <svg
            width="clamp(.8rem,2vw,1.35rem)"
            height="clamp(.8rem,2vw,1.35rem)"
            viewBox="0 0 10 10"
            fill="none"
            style={{ flexShrink: "0" }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5" cy="5" r="5" fill="#DD8C98" />
          </svg>

          <TEXT.MediumHeader
            className="_chartValue"
            style={{
              color: "#000000",
            }}
          >
            {(staked && staked) || 0}
          </TEXT.MediumHeader>
          <TEXT.Disabled
            className="_chartLabel"
            style={{
              color: "#919191",
            }}
          >
            NFTs Staked
          </TEXT.Disabled>
        </Row>
        <Row gap=".5rem">
          <svg
            width="clamp(.8rem,2vw,1.35rem)"
            height="clamp(.8rem,2vw,1.35rem)"
            viewBox="0 0 10 10"
            fill="none"
            style={{ flexShrink: "0" }}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5" cy="5" r="5" fill="#800000" />
          </svg>
          <TEXT.MediumHeader
            className="_chartValue"
            style={{
              color: "#000000",
            }}
          >
            {(loan && loan) || 0}
          </TEXT.MediumHeader>
          <TEXT.Disabled
            className="_chartLabel"
            style={{
              color: "#919191",
            }}
          >
            Pine Loan
          </TEXT.Disabled>
        </Row>
      </Column>
    </ProfileStatsCtr>
  );
};
const ProfileStatsCtr = styled(Row)`
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
  ._chartLabels {
    max-width: 70%;
  }
  ._chartValue,
  ._chartLabel {
    white-space: nowrap;
    font-size: clamp(0.9rem, 2vw, 1.5rem);
    flex-shrink: 0;
    overflow: hidden;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: flex-start;   
    
 `}
`;

const ProfileHeader = ({ profileData }) => {
  const Socials = () => (
    <Row gap=".5rem" className="_socialRedirects">
      {profileData?.discord ? (
        <SquareIconButton
          backgroundColor="#26282B"
          showBorder={false}
          onClick={() => window.open(`${profileData?.discord}`, "_blank")}
        >
          <GenericImageWrapper
            src={assets.logos.discordWhiteLogo}
            size="1rem"
          />
        </SquareIconButton>
      ) : null}
      {profileData?.twitter ? (
        <SquareIconButton
          backgroundColor="#26282B"
          showBorder={false}
          onClick={() => window.open(`${profileData?.twitter}`, "_blank")}
        >
          <GenericImageWrapper src={assets.logos.twitterWhiteLogo} size="1rem" />
        </SquareIconButton>
      ) : null}
      {profileData?.website ? (
        <SquareIconButton
          backgroundColor="#26282B"
          showBorder={false}
          onClick={() => window.open(`${profileData?.website}`, "_blank")}
        >
          <Globe size="1rem" color="#fff" />
        </SquareIconButton>
      ) : null}
    </Row>
  );
  const { mainCollection, setMainCollection } = useAppState();

  let profileID: any;

  if (mainCollection === "aps") {
    profileID = "0x419e82D502f598Ca63d821D3bBD8dFEFAf9Bbc8D";
  } else if (mainCollection === "donsy") {
    profileID = "0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB";
  }

  return (
    <ProfileHeaderCtr>
      <div className="leftImg">
        <img src={assets.images.leftCard} alt="" />
      </div>

      <div className="rightImg">
        <img src={assets.images.rightCard} alt="" />
      </div>
      <div className="main">
        <Row gap="1rem" className="_leftCred">
          <CircledImageCtr radius="0" size={"100px"}>
            <img
              style={{ objectFit: "contain" }}
              src={profileData?.image || assets.icons.defaultCollectionIcon}
              alt=""
            />
          </CircledImageCtr>
          <AutoColumn gap="1rem">
            <Row>
              <p className="_cred">
                <TEXT.LargeHeader className="_profileName ">
                  {profileData?.name}
                </TEXT.LargeHeader>
                <img src={assets.icons.verifyIcon} alt="" />
              </p>
            </Row>
            {
        mainCollection === "all" ? null: 


            <Row gap=".5rem">
              {profileData?.contractAddress && (
                <OutlinedLabel>
                  <TEXT.SmallBody
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      copyTextToClipboard(profileData.contractAddress)
                    }
                  >
                    {profileData?.contractAddress?.length > 0 &&
                      trimAddress(profileData?.contractAddress)}
                  </TEXT.SmallBody>
                  <TEXT.Disabled>
                    <Copy size="1rem" />
                  </TEXT.Disabled>
                </OutlinedLabel>
              )}
              <OutlinedLabel>
                <TEXT.SmallBody>
                  {profileData?.erc_type?.toUpperCase()}
                </TEXT.SmallBody>
              </OutlinedLabel>
              <OutlinedLabel>
                <img
                  onClick={() =>
                    window.open(
                      `https://polygonscan.com/address/${profileData?.contractAddress}`,
                      "_blank"
                    )
                  }
                  src={assets.logos.ethscanLogo}
                  alt=""
                />
              </OutlinedLabel>
            </Row>
                }

            {profileData?.marketplace ? (
              <SocialLinks links={profileData?.marketplace} />
            ) : null}
          </AutoColumn>
        </Row>
        <Socials/>
      {
        mainCollection === "all" ? null: 
        <ProfileStats />

      }

      </div>
    </ProfileHeaderCtr>
  );
};

const ProfileHeaderCtr = styled.div`
  background: linear-gradient(180deg, #f1efef 0%, #fff 100%);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 1px solid #c4c4c4;
  overflow: hidden;

  .leftImg {
    position: absolute;
    left: 0;
    top: 0;
  }
  .rightImg {
    position: absolute;

    right: 0;
    bottom: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
.rightImg{
  display:none;
      
    
    
    }
`}
  .main {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.disabled};
    padding: 1rem;
    position: relative;
    z-index: 100;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction:column; 
   
  `}
    ._cred {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      position: relative;
    }
    ._leftCred {
      ._profileName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 500px;
      }
      ._profileIcon {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      ${({ theme }) => theme.mediaWidth.upToMedium`
      flex-direction:column;
      justify-content:flex-start;
      align-items:flex-start;
      ._profileName{
           max-width: 85%;
        white-space: wrap;

      }
      ._profileIcon{
        
      }

     
      `}
    }
    ._socialRedirects {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: fit-content;
      z-index: 5;
    }
  }
`;
export default ProfileHeader;
