import { linearGradient } from "polished";
import { Colors } from "./styled";

const white = "#FFFFFF";
const black = "#000000";

export function colors(darkMode?: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
    header: darkMode ? "#1C1C1C" : black,
    subHeader: darkMode ? "#000000" : black,
    body: darkMode ? "#000" : black,
    subBody: darkMode ? "#000000" : black,
    label: darkMode ? "rgba(255, 255, 255, 0.7)" : black,

    // backgrounds
    bgBase: darkMode ? "white" : white,
    bgFooter: "#FDFDFD",
    bgMiddle: darkMode ? "rgba(14, 14, 14, 0.7)" : white,
    bgTop: darkMode ? "#000;" : white,
    bgModal: darkMode
      ? "linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.00) 100%), #FFF;"
      : white,
    bgTransparent01: "rgba(255, 255, 255, 0.1)",
    bgTransparent025: "rgba(0, 0, 0, 0.25)",

    //primary colors
    primary: darkMode ? "#AAC0DC" : "#7b3fe4",
    primaryLight: darkMode ? "#7b3fe4" : "#7b3fe4",
    primaryDark: darkMode ? "#513d79" : "#7b3fe4",

    // Secondary colors
    secondary: darkMode ? "#616161" : black,

    // different colors
    grey: "grey",
    blue: "blue",

    // Graphs
    graphAxisElements: "rgba(36, 35, 35, 0.7)",
    graphLines: "#AAC0DC",
    graphArea: "rgba(15, 56, 110, 0.5)", //#aac0dc80

    //  Buttons
    btnPrimary: darkMode ? "#8247e5" : "#7b3fe4",
    btnSecondary: darkMode ? "#4C12F0" : black,

    // Status
    disabled: "#C4C4C4",
    disabledVar2: "linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.00) 100%), #FFF;",
    disabledVar3: "#474747",
    pending: "",
    success: "#12B76A",
    warning: "#FF6F65",

    //Links
    aTag: "blue",

    //shadows
    shadow: darkMode ? "#000" : "#2F80ED",

    // Borders
    border: "#2D2F31",
    border2: "#47484D",
    borderSuccess: "#007C7C",
    borderToggleBtn: "#DCDCDC",
    borderActiveToggleBtn: "#474747",

    // Gradients
    radialGradient:
      "radial-gradient(102.54% 102.54% at 41.56% 100%, rgba(82, 66, 123, 0.4) 0%, rgba(0, 0, 0, 0) 100%), rgba(66, 66, 66, 0.2)",
    activeRadialGradient:
      "background: linear-gradient(180deg, rgba(56, 54, 54, 0.02) 0%, rgba(0, 0, 0, 0.00) 100%), #f8f5f5",
    linearGradient:
      "linear-gradient(0deg, rgba(66, 66, 66, 0.2), rgba(66, 66, 66, 0.2)), radial-gradient(102.54% 102.54% at 50.05% 100%, rgba(82, 66, 123, 0.4) 0%, rgba(0, 0, 0, 0) 100%)",
    activeLinearGradient:
      "linear-gradient(360deg, rgba(95, 61, 153, 0.5) -47.88%, rgba(51, 52, 74, 0) 100%), linear-gradient(0deg, rgba(66, 66, 66, 0.2), rgba(66, 66, 66, 0.2)), radial-gradient(102.54% 102.54% at 50.05% 100%, rgba(82, 66, 123, 0.4) 0%, rgba(0, 0, 0, 0) 100%)",
    linearGradient2: "linear-gradient(180deg, #FFFFFF 0%, #A094CA 81.4%)",
  };
}
