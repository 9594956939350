import Card from "components/Card";
import styled from "styled-components";

export const CHART_DEFAULT_HEIGHT = 500;
export const CHART_DEFULT_HEIGHT_IN_MOBILE = 465;

export const ChartSubWrapper = styled(Card)`
  width: 100%;
  display: flex;
  padding: 0rem;
  flex-direction: column;
  > * {
    font-size: 1rem;
  }
  overflow: hidden;
  // text-indent: 1em;
  // background: ${({ theme }) => theme.linearGradient};
  // border: 0.5px solid ${({ theme }) => theme.border};
  /* box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.4); */
  border-radius: 0px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  overflow-x: auto;
  `}

  :hover,
  :focus {
    // background: ${({ theme }) => theme.bgTop};
  }
`;
export const ChartTooltipWrapper = styled.div`
  background: ${({ theme }) => theme.bgTransparent025};
  border: 0.5px solid ${({ theme }) => theme.primaryLight};
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  backdrop-filter: blur(12px);
  border-radius: ${({ theme }) => theme.borderRadius.card};
  padding: 1rem;
`;
