import axios from "axios";
import { useMemo, useState } from "react";



interface api {
    loading: boolean;
    error: boolean;
    data: any;
}

const getData = async (profileID:string) => {
    try {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL1}/aggregations/nfts/nftHoldersByPeriod?collectionId=${profileID}`

        //   "https://nftsurfaceboard.up.railway.app/api/aggregations/nfts/nftHoldersByPeriod?collectionId=0x419e82D502f598Ca63d821D3bBD8dFEFAf9Bbc8D"
        )
        return data;
    }
    catch (error) {
        return error;
    }
}

export function useHoldDuration(profileID: string) {
    const [api, setAPIData] = useState<api>({
        loading: true,
        error: false,
        data: [] || undefined
    });



    useMemo(async () => {
        const data = await getData(profileID);
        setAPIData({
            loading: false,
            error: false,
            data: data
        })
    }, [])
    return api;
}
