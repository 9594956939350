import assets from "assets";
import { SmallOptionButton } from "components/Button";
import BarChart from "components/Chart/BarChart";
import LineChart from "components/Chart/LineChart";
import { AutoColumn } from "components/Column";
import { HorizontalDivider } from "components/Divider/inde";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { GenericImageWrapper } from "components/Logo";
import { TabularNumsText } from "components/shared";
import { useTotalNFTMint } from "data/nft/totalNFTMint";
import dayjs from "dayjs";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { TEXT } from "theme/texts";
import { ChainType, GenericChartSeries, TimeWindow } from "types";
import getTimewindowFilteredData from "utils/chart";
import { formatAmount } from "utils/numbers";
import {
    appRoutes,
    getScrollToElementPath,
    marketOverviewPageElementIDs,
} from "utils/path";
import { ChartTopRightSectionWrapper, ChartWrapper, ListButtonWrapper } from "../styled";
import { getChainTypeLogo } from "utils/logo";
import Row from "components/Row";
import { useZettaHoldDuration } from "data/yoots/zetta/HoldDuration";
import { useHoldDuration } from "data/yoots/holdDuration";
import { useParams } from "react-router-dom";
import { useAppState } from "Constexts/app.context";
import axios from "axios";

const twitterShareScrollPath = getScrollToElementPath(
    appRoutes.marketOverview,
    marketOverviewPageElementIDs.nftMints
);
const snapshotDownloadIconId = "snapshot-nft-mints-download-icon";
const snapshotBrandLogoId = "snapshot-nft-mints-brand-logo";

const TopRightComponent = ({
    timeWindow,
    setTimeWindow,
    isMobileView,
}: {
    timeWindow: TimeWindow;
    setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
    isMobileView: boolean;
}) => {
    return (
        <ChartTopRightSectionWrapper>
            <ListButtonWrapper>
                {[
                    TimeWindow.threeMonth,
                    TimeWindow.sixMonth,
                    TimeWindow.oneYear,
                    TimeWindow.all,
                ].map((option, index) => {
                    return (
                        <SmallOptionButton
                            key={index}
                            active={timeWindow === option}
                            onClick={() => setTimeWindow(option)}
                        >
                            {option === "custom" ? (
                                <GenericImageWrapper
                                    src={assets.icons.calendarIcon}
                                    size={isMobileView ? "14px" : "16px"}
                                />
                            ) : (
                                option
                            )}
                        </SmallOptionButton>
                    );
                })}
            </ListButtonWrapper>
        </ChartTopRightSectionWrapper>
    );
};

const TopLeftComponent = ({ count }: { count: number }) => {
    return (
        <AutoColumn gap="4px">
            <TEXT.LargeHeader>
                <TabularNumsText>
                    {`${formatAmount(count).toLocaleUpperCase()} Mints`}
                </TabularNumsText>
            </TEXT.LargeHeader>
        </AutoColumn>
    );
};

export default  function HoldDurationGraph({
    data,
    refGraph,
}: {
    data: any
    refGraph: React.RefObject<HTMLElement>;
}) {
    const theme = useTheme();
    const [volumeHover, setVolumeHover] = useState<number | undefined>();
    const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
    const [leftLabel, setLeftLabel] = useState<string | undefined>();
    const [rightLabel, setRightLabel] = useState<string | undefined>();
    const [timeWindow, setTimeWindow] = useState(TimeWindow.oneYear);
    const totalNFTMints = useTotalNFTMint();
    const reverseTotalNFTMints = [...(totalNFTMints.records ?? [])].reverse();
    const { isMobile } = useMatchBreakpoints();
    const [showBarGraph, setShowBarGraph] = useState(true);
    const [chainType, setChainType] = useState(ChainType.polygon);
    const downloadScreenshot = useTakeScreenshot({
        ref: refGraph,
        elementIdsTohide: [snapshotDownloadIconId],
        elementIdsToShow: [snapshotBrandLogoId]
    });
  const { mainCollection, setMainCollection } = useAppState();
  
  const [list, setlistData] = React.useState([]);


    let profileID: any;

    if (mainCollection === "aps") {
      profileID = "0x419e82D502f598Ca63d821D3bBD8dFEFAf9Bbc8D";
    } else if (mainCollection === "donsy") {
      profileID = "0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB";
    }
  
    // const holdDuration = useHoldDuration(profileID);
  
    const holdDurationData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL1}/aggregations/nfts/nftHoldersByPeriod?collectionId=${profileID}`
  
          
        );
        setlistData(await res.data.data.distribution)
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
      holdDurationData();
    }, [mainCollection]);

    useEffect(() => {
        setLiquidityHover(undefined);
        setVolumeHover(undefined);
    }, []);
    // const HoldData =  useHoldDuration(profileID)

    // const PolygonData = useZettaHoldDuration();
    // console.log(PolygonData)
    // let PolygonGraphData = PolygonData?.records?.[0]?.bin && PolygonData?.records?.map((item: any) => {
    //     return {
    //         time: item?.bin,
    //         count: item?.count_wallets
    //     }
    // })

    let HoldGraphData = list &&list?.map((item: any) => {
        return {
            time: item?.name,
            count: item?.value
        }
    })
    console.log( HoldGraphData)
    /**
     * @notice
     * @dev 'formattedVolumeData' function format data according to graph plot format
     * @dev 'getTransformedVolumeData' function filter chart data according to selected timewindow
     * @dev 'formattedChartSeries' change according to requirement
     * @param timewindow is selected option out of provided time options to user for filtering chart data
     * @param time will be for x-axis
     * @param value... must be same as @param datakey of config variable 'formattedChartSeries'
     */
    const formattedChartData = useMemo(() => {
        if (chainType === ChainType.sol && data?.data?.results) {
            return data?.data?.results?.data?.map((item: any) => {
                return {
                    time: item.x,
                    count: item.y?.walletCount
                }
            })
            //     (record) => {
            //         return {
            //             time: record.dateTime,
            //             valueNFTMint: record.countMints,
            //         };
            //     }
            // );
        } else if (chainType === ChainType.polygon && HoldGraphData ) {
            return HoldGraphData?.map((item: any) => {
                return {
                    time: item.time,
                    count: item.count
                }
            })
        }
        else {
            return [];
        }
    }, [totalNFTMints, timeWindow, chainType]);
    /**
     * @notice
     * @dev 'formattedChartSeries'  must change according to requirement
     * Add according requirement (This will plot line graph with provided color and data)
     */
    const formattedChartSeries: GenericChartSeries[] = [
        { dataKey: "count", name: "count", color: theme.graphLines },
    ];

    const filteredTotalCount: number = useMemo(() => {
        if (reverseTotalNFTMints.length === 0) {
            return 0;
        }
        switch (timeWindow) {
            case TimeWindow.threeMonth: {
                const beforeThreeMonthData = reverseTotalNFTMints.find((element) =>
                    dayjs(element.dateTime).isBefore(
                        dayjs().subtract(3, "month"),
                        "month"
                    )
                );
                return (
                    reverseTotalNFTMints[0].cumulativeMints -
                    (beforeThreeMonthData?.cumulativeMints ?? 0)
                );
            }
            case TimeWindow.sixMonth: {
                const beforeSixMonthData = reverseTotalNFTMints.find((element) =>
                    dayjs(element.dateTime).isBefore(
                        dayjs().subtract(6, "month"),
                        "month"
                    )
                );
                return (
                    reverseTotalNFTMints[0].cumulativeMints -
                    (beforeSixMonthData?.cumulativeMints ?? 0)
                );
            }
            case TimeWindow.oneYear: {
                const beforeOneYearData = reverseTotalNFTMints.find((element) =>
                    dayjs(element.dateTime).isBefore(dayjs().subtract(1, "year"), "month")
                );
                return (
                    reverseTotalNFTMints[0].cumulativeMints -
                    (beforeOneYearData?.cumulativeMints ?? 0)
                );
            }
            case TimeWindow.all:
                return reverseTotalNFTMints[0].cumulativeMints;
            default:
                return reverseTotalNFTMints[0].cumulativeMints;
        }
    }, [totalNFTMints, timeWindow]);

    return (
        <>
            <GraphTableTopHeader
                title={"Hold Duration"}
                tooltip="Distribution of how long the NFTs are held by their owners"
              
                downloadScreenshot={downloadScreenshot}
                isMobileView={isMobile}
                snapshotBrandLogoId={snapshotBrandLogoId}
                snapshotDownloadIconId={snapshotDownloadIconId}
                tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights:  @LayerEhq here: ${twitterShareScrollPath} `} />
            <HorizontalDivider />
            {showBarGraph ? (
                <ChartWrapper>
                    <BarChart
                        data={formattedChartData}
                        series={formattedChartSeries}
                        yAxisLabel="Count"
                        setValue={setVolumeHover}
                        setLabel={setRightLabel}
                        value={volumeHover}
                        label={rightLabel}
                        activeWindow={timeWindow}
                    />
                </ChartWrapper>
            ) : (
                <ChartWrapper>
                    <LineChart
                        data={formattedChartData}
                        series={formattedChartSeries}
                        yAxisLabel="NFT Mints"
                        setValue={setVolumeHover}
                        setLabel={setRightLabel}
                        value={volumeHover}
                        label={rightLabel}
                        activeWindow={timeWindow}
                        topRight={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    width: "100%",
                                }}
                            >
                            <ListButtonWrapper withoutDivider={true}>
                                {[ChainType.polygon, ChainType.sol].map((option, index) => {
                                    return (
                                        <SmallOptionButton
                                            key={index}
                                            active={chainType === option}
                                            onClick={() => setChainType(option)}
                                        >
                                            <Row gap="4px">
                                                <GenericImageWrapper
                                                    src={getChainTypeLogo(option)}
                                                    size={isMobile ? "16px" : "12px"}
                                                />
                                                {!isMobile && (
                                                    <TEXT.SmallHeader fontWeight={600} fontSize={12} >
                                                        {option}
                                                    </TEXT.SmallHeader>
                                                )}
                                            </Row>
                                        </SmallOptionButton>
                                    );
                                })}
                            </ListButtonWrapper>
              
                        </div>
                        }
                      
                    />
                </ChartWrapper>
            )}
        </>
    );
}

