import { useAppState } from "Constexts/app.context";
import assets from "assets";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";

const SwitchBtnM = () => {
  const { mainCollection, setMainCollection } = useAppState();
  const navigate = useNavigate();
  const { nftId } = useParams();

  useEffect(() => {
    if (mainCollection === "aps") {
      navigate("/ape");
    } else if (mainCollection === "donsy") {
      navigate("/donsy");
    } else if (mainCollection === "all") {
      navigate("/");
    }
  }, [mainCollection]);

  return (
    <Wrapper>
      {/* <div className="subHead" > */}
        {/* <div className="logoplace">
          <img src={assets.logos.logok} alt="" />
        </div> */}

       
          <div className="switches-container">
            
           <div className={mainCollection === "all" ? "btnbox activeb" :'btnbox'} onClick={()=> setMainCollection("all")}>
            All
           </div>
           <div className={mainCollection === "aps" ? "btnbox activeb" :'btnbox'} onClick={()=> setMainCollection("aps")}>
            0xpolygonApe
           </div>
           <div className={mainCollection === "donsy" ? "btnbox activeb" :'btnbox'} onClick={()=> setMainCollection("donsy")}>
            DonsyNFT
           </div>
          </div>
      
      {/* </div> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
    width: 100%;

  /* .subHead { */
    display: flex;
    /* margin-bottom: 20px; */
    gap: 27px;
    justify-content: center;
    width: 100;
    margin-bottom: 20px;
  /* } */

  .switches-container {
    padding: 0 20px;
    /* width: 23rem; */
    position: relative;
    /* gap: 20px; */
    display: flex;
    padding: 3px ;
    position: relative;
    border-radius: 4px;
    border: 0.5px solid #c4c4c4;
    background: linear-gradient(180deg, #f5f4f4 0%, #fff 100%);
    line-height: 2.5;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    border-radius:4px;
    transition: all 0.5s ease-out;

  }

  .btnbox{
    border-radius:4px;
    padding: 0 20px ;
    font-size: 14px;
    transition: all 0.5s ease-out;

  }
  .activeb {
    background-color:black;
    color:white;
    /* transition: all 0.5s ease-out; */
    font-weight: bold;



  }

 
  ${({ theme }) => theme.mediaWidth.upToLarge`
  
    `}
  
`;
export default SwitchBtnM;
