import React from "react";
import Skeleton from "react-loading-skeleton";

const SKLTCollectionsTable = () => {
  return (
    <div style={{ padding: "0 1rem" }}>
      <Skeleton
        count={8}
        style={{ padding: "1rem", margin: ".5rem 0", maxWidth: "100%" }}
      />
    </div>
  );
};

export default SKLTCollectionsTable;
