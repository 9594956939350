import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "utils/numbers";
import GainLossPercentComponent from "components/Percent";
import { ChevronDown, ChevronUp } from "react-feather";
import { CircledImageCtr } from "components/Logo";
import assets from "assets";
import { useCurrencyUnit } from "store/user/hooks";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import { DataTableWrapper } from "pages/YootsDashboard/component";

const TableHeadItem = ({ item, selectedItem }: any) => {
  return (
    <th
      className="_heading"
      onClick={item?.action ? item?.action : () => {}}
      style={item?.action ? { cursor: "pointer" } : {}}
    >
      <span>
        {item.heading}
        {item?.action ? (
          item?.sort_dir === "asc" && item?.sort_item === selectedItem ? (
            <ChevronUp size={"1rem"} />
          ) : (
            <ChevronDown size={"1rem"} />
          )
        ) : null}
      </span>
    </th>
  );
};

const TableDataItem = ({ item, column }: any) => {
  const navigate = useNavigate();
  const currency = useCurrencyUnit();
  return (
    <tr className="_tableData" key={item.id}>
      {column.map((colItem: any, idx: number) => {
        if (colItem.value === "no") {
          return (
            <td key={idx} className="_dataCell">
              {item[`${colItem.value}`]}
            </td>
          );
        } else if (
          colItem.value === "proportion" ||
          colItem.value === "profit" ||
          colItem.value === "volume_change"
        ) {
          return (
            <td key={idx} className={"_dataCell"}>
              <GainLossPercentComponent
                value={parseFloat(item[`${colItem.value}`])}
                formatFig={true}
                fontSize={".8rem"}
              />
            </td>
          );
        } else
          return (
            <td key={idx} className="_dataCell">
              {colItem.value === "name" ? (
                <div className="profileName">
                  <CircledImageCtr size="36px" radius=".25rem">
                    <img
                      src={
                        item[`${colItem.logo}`] ||
                        assets.icons.defaultCollectionIcon
                      }
                      alt={item[`${colItem.value}`]}
                    />
                  </CircledImageCtr>
                  <span>{item[`${colItem.value}`]}</span>
                </div>
              ) : colItem.value === "wallet" ? (
                <div className="profileName">
                <span>
                  {item[`${colItem.value}`]?.slice(0, 6) +
                    "...." +
                    item[`${colItem.value}`]?.slice(-4)}
                      
                </span>
                {/* {
                  item[`${colItem.username}`] !== false 
                 &&
                <CircledImageCtr size="16px" 
                        onClick={() => 
                          window.open(
                            "https://twitter.com/" + item[`${colItem?.username}`],
                            "_blank"
                          )
                        }
                      >
                    <img
                      src={
                        assets.logos.twitterWhiteLogo
                      }
                      alt={item[`${colItem.username}`]}
                    />
                  </CircledImageCtr> } */}
                </div>
              ) : colItem.value === "floor" ? (
                <span>
                  {formatAmount(
                    parseFloat(item[`${colItem.value}`])
                  ).toUpperCase()}
                </span>
              ) : (
                <span className="currencyLogo">
                  {colItem.value?.includes("in_") && (
                    <CircledImageCtr size="15px">
                      <img
                        src={currency?.currency?.logo}
                        alt="currency"
                        style={{ objectFit: "contain" }}
                      />
                    </CircledImageCtr>
                  )}

                  {item[`${colItem.value}`] === undefined ||
                  item[`${colItem.value}`] === null
                    ? 0
                    : colItem.value === "sales"
                    ? formatAmount(
                        parseFloat(item[`${colItem.value}`]),
                        0
                      ).toUpperCase()
                    : formatAmount(
                        parseFloat(item[`${colItem.value}`])
                      ).toUpperCase()}
                </span>
              )}
            </td>
          );
      })}
    </tr>
  );
};
const DataTable = ({ data, column, sortItemLabel }: any) => {
  return (
    <DataTableCtr>
      <table className="_table">
        <thead>
          <tr className="_headings">
            {column.map((item: any, idx: any) => (
              <TableHeadItem
                item={item}
                key={idx}
                selectedItem={sortItemLabel}
              />
            ))}
          </tr>
        </thead>
        <tbody className="_tableBody">
          {data.map((item: any, idx: any) => {
            return (
              <TableDataItem item={item} column={column} key={idx} id={idx} />
            );
          })}
        </tbody>
      </table>
    </DataTableCtr>
  );
};

const DataTableCtr = styled.div`
  min-height: 535px;
  overflow: hidden;
  overflow-x: auto;
  position: relative;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    width: 10px;
    height: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.disabledVar3};
  }
  table {
    border-collapse: collapse !important;
    border-spacing: 0px !important;
  }
  ._table {
    width: 100%;
    font-family: var(--ff-subtitle);
  }
  ._headings {
    border-top: 1px solid ${(props) => props?.theme?.disabledVar3};
    border-bottom: 1px solid ${(props) => props?.theme?.disabledVar3};
    font-family: var(--ff-title);
    display: flex;
  }
  ._heading {
    flex: 1;
    min-width: 125px;
    white-space: nowrap;
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;
    &:nth-of-type(2) {
      min-width: 250px;
      justify-content: initial;
    }
    &:nth-of-type(1) {
      min-width: initial;
      flex: 0;
      min-width: 50px;
    }
    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
        justify-content:center;
        &:nth-of-type(1), &:nth-of-type(2) {
          position:sticky;
          left:0;
          top:0;
          z-index:10;
            background:${(props) => props?.theme?.bgModal};
    }
    &:nth-of-type(2){
     min-width: 200px;
         border-right: 1px solid ${(props) => props?.theme?.disabledVar3};
    }
      `}
  }
  ._tableBody {
    ${(props) => props?.theme?.flexColumnNoWrap};
    margin: 0;
  max-height: 500px;
  overflow:auto !important;

  ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */


  }
  table {
    border-collapse: collapse !important;
    border-spacing: 0px !important;
    padding: 0;
    margin: 0;
  }
  ._tableData {
    display: flex;
    cursor: pointer;
    min-height: 50px;
    &:nth-last-of-type(1) {
      td {
        padding-bottom: 2rem;
      }
    }
    ._dataCell {
      flex: 1;
      min-width: 125px;
      display: flex;
      align-items: center;
      padding: 0.5rem;
      &:nth-of-type(1) {
        flex: 0;
        min-width: 50px;
      }
      &:nth-of-type(2) {
        min-width: 250px;
        justify-content: initial;
      }
      .currencyLogo {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.15rem;
      }
      .profileName {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .profileImg {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
        }
        span {
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: initial !important;
        }
      }
      //@media quries : mobile screens
      ${({ theme }) => theme.mediaWidth.upToLarge`
         span {
        text-align: center;
        display: flex;
        justify-content: center;
      }
      justify-content:center;
        &:nth-of-type(1), &:nth-of-type(2) {
          position:sticky;
          left:0;
          top:0;
              z-index:10;
          background:${(props) => props?.theme?.bgModal};
    }
    &:nth-of-type(2){
     min-width: 200px;
              border-right: 1px solid ${(props) => props?.theme?.disabledVar3};
    }
      `}
    }
  }
`;

export default DataTable;
