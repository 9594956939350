import Skeleton from "react-loading-skeleton";

const SKLTTable = ({ rowCount }: { rowCount: number }) => {
  return (
    <div style={{ padding: "0 1rem" , width:"100%"}} >
      <Skeleton
        count={rowCount}
        style={{ padding: "1rem", margin: ".5rem 0", maxWidth: "100%" }}
      />
    </div>
  );
};

export default SKLTTable;
