import { Suspense, useEffect, useState, useLayoutEffect } from "react";
import React, { Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { ENDPOINT_CLIENT } from "../apollo/endpoints";
import { DarkGreyCard } from "../components/Card";
import { AnimatedLoader } from "../components/Loader";
import { ExternalLink } from "../theme/components";

import Layout from "./Layout";

import NewLoader from "components/Loader/NewLoader";
import { SkeletonTheme } from "react-loading-skeleton";
import YootsDashboard from "./YootsDashboard";
import ProtectedRoutes from "components/ProtectedRoutes";
import YootsLogin from "./YootsDashboard/Login";
import Combaind from "./YootsDashboard/Combaind";

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
  min-height: 100vh;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  position: fixed;
  justify-content: space-between;
  z-index: 2;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  // > * {
  //   max-width: 1200px;
  // }
  @media (max-width: 1080px) {
    /*padding-top: 2rem;
     margin-top: 140px;  why to have this margin top /Padding ??? @shaswa7  */
  }
`;

const Marginer = styled.div`
  margin-top: 5rem;
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WarningBanner = styled.div`
  background-color: ${({ theme }) => theme.bgBase};
  padding: 1rem;
  color: ${({ theme }) => theme.white};
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-weight: 500;
`;

// const BLOCK_DIFFERENCE_THRESHOLD = 30;

export default function App() {
  // load buffer
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1300);
  }, []);
  const showNotSyncedWarning = true;

  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <Suspense fallback={null}>
        {loading ? (
          <NewLoader />
        ) : (
          <Layout>
            <AppWrapper>
              {false ? ( //"subgraphStatus.available" === "false"
                <AppWrapper>
                  <BodyWrapper>
                    <DarkGreyCard style={{ maxWidth: "340px" }}>
                      <TEXT.MediumHeader>
                        The Graph hosted network which provides data for this
                        site is temporarily experiencing issues. Check current
                        status{" "}
                        <ExternalLink href={ENDPOINT_CLIENT}>
                          here.
                        </ExternalLink>
                      </TEXT.MediumHeader>
                    </DarkGreyCard>
                  </BodyWrapper>
                </AppWrapper>
              ) : (
                <BodyWrapper>
                  <Routes>
                    {/* <Route path="/login" element={<YootsLogin />} />
                    <Route element={<ProtectedRoutes.YootsProtectedRoutes />}> */}
                      <Route path="/"  element={<Combaind />} />

                      <Route path="/:nftId" element={<YootsDashboard />} />
                    {/* </Route> */}
                  </Routes>
                </BodyWrapper>
              )}
            </AppWrapper>
          </Layout>
        )}
      </Suspense>
    </SkeletonTheme>
  );
}
