import axios from "axios";
import ContentCardCtr from "components/ContentCard";
import DataTable from "./DataTable";
import { DropDownCard } from "components/DropDown";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  ForwardedRef,
} from "react";
import { AlertCircle, ArrowLeft, ArrowRight, Search } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionsTable from "components/Skeletons/CollectionsPage/SKLTCollectionsTable";
import { ListButtonWrapper } from "pages/YootsDashboard/styled";
import { ChainType } from "types";
import { SmallOptionButton } from "components/Button";
import { HorizontalDivider } from "components/Divider/inde";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import TopHeaderDyanmicRightComponent from "components/HeaderComponent/TopHeaderDynamicRightComponent";
import { GenericImageWrapper } from "components/Logo";
import { getChainTypeLogo } from "utils/logo";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import {
  appRoutes,
  getScrollToElementPath,
  yootsDashboardElementIDs,
} from "utils/path";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import { ethers, utils, providers } from "ethers";

import { useTokensMutual } from "data/yoots/tokensMutuals";
import { useZettaMutualTokens } from "data/yoots/zetta/MutualToken";
import { useAppState } from "Constexts/app.context";
import { ABIH, ADDRESSH } from "data/yoots/zetta/HUSK";
import { Token } from "graphql";
import { CSVLink } from "react-csv";
import assets from "assets";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { ABIStack, ADDRESSStack } from "data/yoots/zetta/stacked";
const twitterShareScrollPath = getScrollToElementPath(
  appRoutes.yootsDashabord,
  yootsDashboardElementIDs.listingsAndSalesTable
);
const snapshotDownloadIconId = "snapshot-nft-mints-download-icon";
const snapshotBrandLogoId = "snapshot-nft-mints-brand-logo";

const CollectionsTableNav = ({ results, page, setPage }: any) => {
  const firstPage = () => {
    if (page > 1) setPage(1);
  };
  const prevPage = () => {
    if (page > 1) setPage((prev: number) => (prev -= 1));
  };

  const nextPage = () => {
    if (page < results.totalPages) setPage((prev: number) => (prev += 1));
  };
  const lastPage = () => {
    if (page < results.totalPages) setPage(results.totalPages);
  };

  const pagesArray = Array(results.totalPages).map((_, index) => index + 1);
  return results?.totalPages > 0 ? (
    <div className="_navs">
      <button className="_staticNavs" onClick={firstPage}>
        First
      </button>
      <nav className="_tableNav">
        <button onClick={prevPage} disabled={page === 0} className="_navBtn">
          <ArrowLeft size={"1rem"} />
        </button>
        {/* Removed isPreviousData from PageButton to keep button focus color instead */}

        <button className="_navPageBtn">
          Page {page} of {results.totalPages}
        </button>

        <button
          onClick={() => nextPage()}
          disabled={page === results.totalPages}
          className="_navBtn"
        >
          <ArrowRight size={"1rem"} />
        </button>
      </nav>
      <button className="_staticNavs" onClick={lastPage}>
        Last
      </button>
    </div>
  ) : null;
};

const TopTokensHeldByUsersTable = ({
  refTable,
}: {
  refTable: React.RefObject<HTMLElement>;
}) => {
  //constants
  const { currency } = useCurrencyUnit();
  const mutalTokesnData = useTokensMutual();
  //states
  const [tableData, setTableData] = useState<any>();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(null);
  const [sortDir, setSortDir] = useState<string | undefined>();
  const [query, setQuery] = useState(""); //page query endpoint
  const [currentTime, setCurrentTime] = useState(2);
 

  const [category, setCategory] = useState({
    id: 0,
    name: "All",
  });

  const [selectedDate, setSelectedDate] = useState<string | undefined | null>(null);

  // const [selectedDate, setSeclectdDate] = useState<string | undefined | null>(
  //   undefined
  // );


  const [AvailableCategories, setAvailableCategories] = useState([]);
  const [chainType, setChainType] = useState(ChainType.polygon);
  const { isMobile } = useMatchBreakpoints();
  const downloadScreenshot = useTakeScreenshot({
    ref: refTable,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });

  //callbacks
  const fetchData = useCallback(async () => {
    let res = await axios.get(
      process.env.REACT_APP_API_URL +
        `/topCollection?duration=${currentTime}&page=${page}&search=${query}&sortBy=${sortBy}&sortDirection=${sortDir}&category=${category?.name}`
    );
    setTableData(res.data);
    setAvailableCategories(
      res.data?.category?.map((item: any, index: any) => {
        return {
          id: index,
          name: item,
        };
      })
    );
    if (category?.id > 0 && !res.data?.category?.includes(category?.name)) {
      setCategory({
        id: 0,
        name: "All",
      });
    }
  }, [currentTime, category, page, query, sortBy, sortDir]);
  // const PolygonData = useZettaMutualTokens();
  // let PolygonTableData =
  //   (PolygonData?.records?.[0]?.erc20_token &&
  //     PolygonData?.records
  //       ?.map((item: any, index: any) => {
  //         return {
  //           no: index + 1,
  //           token_name: item?.erc20_token,
  //           wallet: item?.mutual_count,
  //         };
  //       })
  //       ?.slice(0, 10)) ||
  //   [];
  // PolygonTableData = PolygonTableData?.length > 0 ? PolygonTableData?.filter((item: any) => !item?.token_name?.includes('0x')) : PolygonTableData;
  // let solanaData =
  //   (mutalTokesnData?.data &&
  //     mutalTokesnData?.data?.data
  //       ?.filter((item: any) => item?.address)
  //       ?.map((item: any, index: any) => {
  //         return {
  //           no: index + 1,
  //           token_name: item?.name,
  //           wallet: item?.num,
  //           mean_usd: item?.mean,
  //           median_usd: item?.median,
  //         };
  //       })
  //       ?.slice(0, 10)) ||
  //   [];

  const columns = [
    {
      id: 1,
      heading: "",
      value: "no",
    },

    // {
    //   id: 2,
    //   heading: "Tokens",
    //   value: "token_name",
    // },
    {
      id: 3,
      heading: "Wallet",
      value: "wallet",
    },
    // {
    //   id: 4,
    //   heading: "HUSK Token",
    //   value: "HUSK_Token",
    // },
    
    {
      id: 4,
      heading: "NFT on Wallet",
      value: "inWallet",
    },
    {
      id: 4,
      heading: "Staked",
      value: "stakedCount",
    },
    {
      id: 5,
      heading: "Total NFT",
      value: "totalNFT",
    },
  ];

  const { mainCollection, setMainCollection } = useAppState();
  const [financialProfiles, setFinancialProfileData] = useState<any>();

  const [owners, setOwners] = useState<any>([]);

  const [list, setlistData] = React.useState({});

  let profileID: any;

  if (mainCollection === "aps") {
    profileID = "APE";
  } else if (mainCollection === "donsy") {
    profileID = "donsy";
  }
  const FetchHUSKBalance = async (address: any) => {
    try {
      // const provider = new providers.JsonRpcProvider("https://rpc.ankr.com/eth");
      const provider = new ethers.providers.JsonRpcProvider(
        "https://polygon-rpc.com"
      );
      let contract = new ethers.Contract(ADDRESSH, ABIH, provider);
      const balance = await contract.balanceOf(address);

      console.log(balance, "fgfd ");

      // const balance = await provider.getBalance(address);
      // return utils.formatEther(balance);
      return Number(balance._hex)
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const FetchOwners = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/collection/owners/${profileID}`
      );
      let data = res?.data?.data?.TopOwners?.map(async (item: any) => {
        let ensName = false;
        return {
          ...item,
          ens: ensName
            ? ensName
            : item?.wallet?.slice(0, 6) + "..." + item?.wallet?.slice(-4),
        };
      });
      data = await Promise.all(data);
      // console.log(data,"fdsfffsdfsdgdgdfgdfgdfgdf");

      setOwners(data);
      if (res?.data?.data?.TopOwners?.length > 0) {
        console.log(res?.data?.data?.TopOwners);
        // FetchFinancialProfiles(
        //   res?.data?.data?.TopOwners?.slice(0, 20).map(
        //     (item: any) => item?.wallet
        //   )
        // );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (e: any) => {
    const selectedDate = e;
    // setDate(selectedDate);

    // Convert the date to 'YYYY-MM-DD' format
    if (selectedDate) {
      const dateObj = new Date(selectedDate);
      const dd = String(dateObj.getDate()).padStart(2, "0");
      const mm = String(dateObj.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = String(dateObj.getFullYear());

      const convertedDate = `${yyyy}-${mm}-${dd}`;
      const ner = new Date(selectedDate).toISOString().split("T")[0];
      console.log(ner,"dfghdhfh");
      setSelectedDate(ner);
      console.log(selectedDate)
    } else {
      setSelectedDate("");
    }
  };
  function weiToEther(weiValue: any) {
    const weiInOneEther = BigInt("1000000000000000000"); // 1 ether = 10^18 wei
    return Number(weiValue) / Number(weiInOneEther);
  }

  const FetchStacked = async (address: any) => {
    try {
      // const provider = new providers.JsonRpcProvider("https://rpc.ankr.com/eth");
      const provider = new ethers.providers.JsonRpcProvider(
        "https://polygon-rpc.com"
      );
      let contract = new ethers.Contract(ADDRESSStack, ABIStack, provider);
      const balance = await contract.getOwnedTokens(address, 0);

      console.log(balance, "sdsfed");
      // const balance = await provider.getBalance(address);
      // return utils.formatEther(balance);
      return balance;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };
  const FetchFinancialProfiles = async () => {
    try {
      console.log(selectedDate,`${process.env.REACT_APP_API_URL}/ape/ownerboard?type=${profileID}${
        selectedDate ? "&timestamp=" + selectedDate : ""
      }`)
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/ape/ownerboard?type=${profileID}${
          selectedDate  ? "&timestamp=" + selectedDate : ""
        }`
      );
  
      console.log(res,"dfdsfdsfdssd")
      let data = await res?.data?.records
        ?.sort((a: any, b: any) => b?.total_nfts_owned - a?.total_nfts_owned)
        ?.map(async (item: any, index: number) => {
          return {
            no: index + 1,
            wallet: item?.wallet_address,
           
            HUSK_Token:  weiToEther(item?.token_balance),
            // await FetchHUSKBalance(item?.wallet_address),
            // weiToEther(item?.token_balance),
            // stacked: await FetchStacked(item?.wallet_address),
            // solVolume: Number(item?.nftsPurchasesAvgPrice),
            totalNFT: Number(item?.total_nfts_owned),
            inWallet: Number(item?.wallet),

            stakedCount: Number(item?.staked_count),

            // totalUsdValue: Number(item?.nftsSalesVolume),
            // diff: Number(item?.nftsTotalNetProfit)
          };
        });
      // let balance = await FetchEthBalance(item?.walletAddress);
      //   let husk = await FetchHUSKBalance(item?.walletAddress);
      //   return {
      //     ...item,
      //     //  balance: balance,
      //     husk: husk,
      //   };
      // });
      data = await Promise.all(data);

      setFinancialProfileData(await data);
      // console.log(data,"sdsdsadfeasdfsdfsdfdsfsd");
    } catch (error) {
      console.log(error);
    }
  };

  // let polygonData =
  //   (financialProfiles?.length > 0 &&
  //     financialProfiles
  //       ?.sort((a: any, b: any) => b?.total_nfts_owned - a?.total_nfts_owned)
  //       ?.map((item: any, index: number) => {
  //         return {
  //           no: index + 1,
  //           wallet: item?.wallet_address,
  //           HUSK_Token: item?.token_balance,
  //           // solVolume: Number(item?.nftsPurchasesAvgPrice),
  //           totalNFT: weiToEther(item?.total_nfts_owned),
  //           // totalUsdValue: Number(item?.nftsSalesVolume),
  //           // diff: Number(item?.nftsTotalNetProfit)
  //         };
  //       })
  //       ?.slice(0, 10)) ||
  //   [];

  const [first, setfirst] = useState(1);

  useEffect(() => {
    if (first <= 3) {
      setfirst(first + 1);
    }
    // fetchData();

    FetchFinancialProfiles();
  }, [mainCollection, first, selectedDate]);
  const dateInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.click();
    }
  };
  interface ExampleCustomInputProps {
    value: string;
    onClick: any;
  }

  const [startDate, setStartDate] = useState<Date>(new Date());
  const ExampleCustomInput = forwardRef<
    HTMLButtonElement,
    Partial<ExampleCustomInputProps>
  >(({ value, onClick }, ref) => (
    <div className="example-custom-input" onClick={onClick}>
      {/* {value} */}

      <GenericImageWrapper src={assets.icons.calendarIcon} />
    </div>
  ));

  return (
    <CollectionsTableCtr>
      {/* <GraphTableTopHeader
                title="Top Tokens held by users"
                isMobileView={isMobile}
                tooltip="Common tokens held by the holders of Yoots"
                tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights:  @LayerEhq here: ${twitterShareScrollPath} `}

            />
            <div
              style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                padding: "0 1rem",
                 
              }}
           >
              <ListButtonWrapper withoutDivider={true}>
                  {[ChainType.polygon, ChainType.sol].map((option, index) => {
                      return (
                          <SmallOptionButton
                              key={index}
                              active={chainType === option}
                              onClick={() => setChainType(option)}
                          >
                              <Row gap="4px">
                                  <GenericImageWrapper
                                      src={getChainTypeLogo(option)}
                                      size={isMobile ? "16px" : "12px"}
                                  />
                                  {!isMobile && (
                                      <TEXT.SmallHeader fontWeight={600} fontSize={12} >
                                          {option}
                                      </TEXT.SmallHeader>
                                  )}
                              </Row>
                          </SmallOptionButton>
                      );
                  })}
              </ListButtonWrapper>

          </div> */}
      <TopHeaderDyanmicRightComponent
        title="List of Holders with NFTs Owned"
        tooltip="Common tokens held by the holders of Polygon Ape"
        rightComponent={
          <div className="rightComponent">
            {financialProfiles ? (
              <CSVLink
                data={financialProfiles}
                filename={`TradeLeaderboard-${new Date()?.toDateString()}.csv`}
                className="exportBtn"
                target="_blank"
              >
                <img src={assets.icons.downloadIcon} alt="download" />
              </CSVLink>
            ) : null}
            <DatePicker
              className="dda"
              selected={startDate}
              onChange={(date: Date) => {
                setStartDate(date);
                handleDateChange(date);
              }}
              customInput={<ExampleCustomInput />}
            />
            {/* <input type="date" name="" id="" onChange={handleDateChange}/> */}
          </div>
        }
      />
      {financialProfiles ? (
        <DataTable
          column={columns}
          // data={chainType === ChainType.polygon ? PolygonTableData : solanaData}
          data={financialProfiles || []}
          sortItemLabel={sortBy}
        />
      ) : (
        <SKLTCollectionsTable />
      )}
      <HorizontalDivider />
    </CollectionsTableCtr>
  );
};

const CollectionsTableCtr = styled.div`
  gap: 0;
  .dda {
  }
  .date-button-container {
    position: relative;
    display: inline-block;
  }

  .transparent-input {
    position: absolute;
    /* opacity: 0; */
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
  }

  .date-button-overlay {
    position: relative;
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    text-align: center;
  }

  .date-button-overlay:hover {
    background-color: #0056b3;
  }

  .rightComponent {
    display: flex;
    gap: 12px;
  }
  .example-custom-input {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    height: 40px;
    width: fit-content;
    background-color: #000000;
    color: #919191;
    border: 1px solid #474747;
    border-radius: 8px;
    padding: 8px 10px;
    text-align: center;
    text-decoration: none;
  }
  .react-datepicker-popper {
    z-index: 200;
  }
  .exportBtn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #000000;
    color: #919191;
    border: 1px solid #474747;
    border-radius: 8px;
    padding: 8px 10px;
    text-align: center;
    text-decoration: none;
  }
  ._navs {
    ${(props) => props?.theme?.flexRowNoWrap}
    gap:.25rem;
    width: fit-content;
    margin: 1rem;
    margin-left: auto;
    button {
      background: none;
      outline: none;
      border: none;
      color: ${(props) => props?.theme?.body};
      font-family: var(--ff-subtitle);
      display: grid;
      place-items: center;
      padding: 0.5rem;
      font-size: var(--fs-s) !important;
    }

    ._staticNavs {
      width: 70px;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
    }

    ._tableNav {
      ${(props) => props?.theme?.flexRowNoWrap}
      gap:.25rem;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
      ._navPageBtn {
        min-width: 100px;
      }
      ._navBtn {
        &:nth-of-type(1) {
          border-right: 1px solid ${(props) => props?.theme?.disabled};
        }
        &:nth-of-type(3) {
          border-left: 1px solid ${(props) => props?.theme?.disabled};
        }
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      margin:1rem auto;
      `}
  }

  ._tableHeader {
    padding: 1.25rem 1rem;
    ${(props) => props?.theme?.flexRowNoWrap}
    justify-content: space-between;
    align-items: center;
    ._rightHeaderCtr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    ._tableTitle {
      font-family: var(--ff-title);
      font-size: var(--fs-l);
      display: flex;
      gap: 0.5rem;
    }
    ._searchResultsBar {
      ${(props) => props?.theme?.flexRowNoWrap}
      align-items: center;
      gap: 0.5rem;
      border: 1px solid ${(props) => props?.theme?.white};
      width: 200px;
      padding: 0 0.5rem;
      border-radius: 0.25rem;

      input {
        background: none;
        color: ${(props) => props?.theme?.body};
        border: none;
        outline: none;
        padding: 0.5rem;
        font-family: var(--ff-subtitle);
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-direction:column;
        gap:1rem;
        align-items:flex-start;
        padding:.75rem 1rem;
        ._rightHeaderCtr{
            flex-direction:column;
        width:fit-content;
        align-items:flex-start;
        margin-right:auto;
        }
      `}
  }
`;

export default TopTokensHeldByUsersTable;
