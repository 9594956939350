import axios from "axios";
import ContentCardCtr from "components/ContentCard";
import DataTable from "./DataTable";
import { DropDownCard } from "components/DropDown";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import React, { useCallback, useEffect, useState } from "react";
import { AlertCircle, ArrowLeft, ArrowRight, Search } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionsTable from "components/Skeletons/CollectionsPage/SKLTCollectionsTable";
import { usePolygonLiveActivity } from "data/yoots/LiveActivity";
import { useParams } from "react-router-dom";
import { useAppState } from "Constexts/app.context";




const CollectionsTableNav = ({ results, page, setPage }: any) => {
    const firstPage = () => {
        if (page > 1) setPage(1);
    };
    const prevPage = () => {
        if (page > 1) setPage((prev: number) => (prev -= 1));
    };

    const nextPage = () => {
        if (page < results.totalPages) setPage((prev: number) => (prev += 1));
    };
    const lastPage = () => {
        if (page < results.totalPages) setPage(results.totalPages);
    };

    const pagesArray = Array(results.totalPages)
        .map((_, index) => index + 1);
    return results?.totalPages > 0 ? (
        <div className="_navs">
            <button className="_staticNavs" onClick={firstPage}>
                First
            </button>
            <nav className="_tableNav">
                <button onClick={prevPage} disabled={page === 0} className="_navBtn">
                    <ArrowLeft size={"1rem"} />
                </button>
                {/* Removed isPreviousData from PageButton to keep button focus color instead */}

                <button className="_navPageBtn">
                    Page {page} of {results.totalPages}
                </button>

                <button
                    onClick={() => nextPage()}
                    disabled={page === results.totalPages}
                    className="_navBtn"
                >
                    <ArrowRight size={"1rem"} />
                </button>
            </nav>
            <button className="_staticNavs" onClick={lastPage}>
                Last
            </button>
        </div>
    ) : null;
};


const LiveActivityTable = () => {
    //constants
    const SORT_DIR = { ASEC: "asec", DESC: "desc" };
    const SORT_BY = { FLOOR: "floor", VOL: "volume", SALES: "sales" };
    const { currency } = useCurrencyUnit();
    //states
    const [tableData, setTableData] = useState<any>();
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState(null);
    const [sortDir, setSortDir] = useState<string | undefined>();
    const [query, setQuery] = useState(""); //page query endpoint
    const [currentTime, setCurrentTime] = useState(2);
    // type LiveActivityType = { content: any[] } | undefined;
    const [liveActivity, setliveActivity] = useState<any[]>([]);
    const [category, setCategory] = useState({
        id: 0,
        name: "All",
    });
    const { mainCollection, setMainCollection } = useAppState();

    let profileID : any; 
  
    if(mainCollection === "aps"){
     profileID = '0x419e82D502f598Ca63d821D3bBD8dFEFAf9Bbc8D';


    } else if(mainCollection === "donsy"){
     profileID = '0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB';


    }
    

    const [AvailableCategories, setAvailableCategories] = useState([]);
    const PolygonData = usePolygonLiveActivity(profileID)

    const fetchLiveActivity = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL1}/aggregations/liveActivity?contractAddress=${profileID}`

          );

          setliveActivity(await res?.data?.data?.content);
          
        } catch (error) {
          console.log(error);
        }
      };



    let PolygonTableData = (liveActivity?.length > 0 && liveActivity?.map((item:any,index:number) => {
        return {
            no: index + 1,
            from: item?.from,
            to: item?.to,
            method: item?.event_type,
            value: item?.trade_price || 0,
            time: new Date(item?.timestamp )?.toISOString().split('T')[0]?.toLocaleString(),
            tx_hash: item?.hash,
        }
    })) || [];
  

    // let PolygonTableData = (PolygonData?.data?.items?.length > 0 && PolygonData?.data?.items?.map((item:any,index:number) => {
    //     return {
    //         no: index + 1,
    //         from: item?.order?.maker,
    //         to: item?.order?.taker,
    //         method: item?.order?.type === "listing" ? "Sale" : item?.order?.type,
    //         value: item?.order?.askAmount / 1000000000000000000 || 0,
    //         time: new Date(item?.timestamp)?.toLocaleString(),
    //         tx_hash: item?.txHash,
    //     }
    // })) || [];
    //callbacks
    const fetchData = useCallback(async () => {
        let res = await axios.get(
            process.env.REACT_APP_API_URL +
            `/topCollection?duration=${currentTime}&page=${page}&search=${query}&sortBy=${sortBy}&sortDirection=${sortDir}&category=${category?.name}`
        );
      
        setTableData(res.data);
        setAvailableCategories(
            res.data?.category?.map((item: any, index: any) => {
                return {
                    id: index,
                    name: item,
                };
            })
        );
        if (category?.id > 0 && !res.data?.category?.includes(category?.name)) {
            setCategory({
                id: 0,
                name: "All",
            });
        }
    }, [currentTime, category, page, query, sortBy, sortDir, mainCollection]);
    const sortItem = ({ sort_by }: any) => {
        setSortBy(sort_by);
        setSortDir(sortDir === SORT_DIR.ASEC ? SORT_DIR.DESC : SORT_DIR.ASEC);
    };

    const columns = [
        {
            id: 1,
            heading: "",
            value: "no",
        },
        {
            id: 2,
            heading: "Method",
            value: "method",
        },
       
        {
            id: 3,
            heading: "From",
            value: "from",
        },
        {
            id: 4,
            heading: "To",
            value: "to",
        },
       
      
        {
            id: 5,
            heading: "Value",
            value: "value"
        },
        {
            id: 6,
            heading: "Tx Hash",
            value: "tx_hash",
        },
        {
            id: 7,
            heading: "Time",
            value: "time",
        },
        
    ];

    useEffect(() => {
        // fetchData();
        fetchLiveActivity()
    }, [mainCollection]);

    return (
        <CollectionsTableCtr>
            <ContentCardCtr >
                <div className="_tableHeader">
                    <TEXT.MediumHeader>
                        <span>Live Activity</span>
                        <QuestionHelper text="List of live activity for rektdogs, whether its listing, sales, offers, transfers" />
                    </TEXT.MediumHeader>
                   
                </div>
                {PolygonTableData?.length > 0 ? (
                    <DataTable
                        column={columns}
                        data={PolygonTableData?.slice(0, 10)}
                        sortItemLabel={sortBy}
                    />
                ) : (
                    <SKLTCollectionsTable />
                )}
            </ContentCardCtr>
        </CollectionsTableCtr>
    );
};

const CollectionsTableCtr = styled.div`
  gap: 0;
  ._navs {
    ${(props) => props?.theme?.flexRowNoWrap}
    gap:.25rem;
    width: fit-content;
    margin: 1rem;
    margin-left: auto;
    button {
      background: none;
      outline: none;
      border: none;
      color: ${(props) => props?.theme?.body};
      font-family: var(--ff-subtitle);
      display: grid;
      place-items: center;
      padding: 0.5rem;
      font-size: var(--fs-s) !important;
    }

    ._staticNavs {
      width: 70px;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
    }

    ._tableNav {
      ${(props) => props?.theme?.flexRowNoWrap}
      gap:.25rem;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
      ._navPageBtn {
        min-width: 100px;
      }
      ._navBtn {
        &:nth-of-type(1) {
          border-right: 1px solid ${(props) => props?.theme?.disabled};
        }
        &:nth-of-type(3) {
          border-left: 1px solid ${(props) => props?.theme?.disabled};
        }
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      margin:1rem auto;
      `}
  }

  ._tableHeader {
    padding: 1.25rem 1rem;
    ${(props) => props?.theme?.flexRowNoWrap}
    justify-content: space-between;
    align-items: center;
    ._rightHeaderCtr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    ._tableTitle {
      font-family: var(--ff-title);
      font-size: var(--fs-l);
      display: flex;
      gap: 0.5rem;
    }
    ._searchResultsBar {
      ${(props) => props?.theme?.flexRowNoWrap}
      align-items: center;
      gap: 0.5rem;
      border: 1px solid ${(props) => props?.theme?.white};
      width: 200px;
      padding: 0 0.5rem;
      border-radius: 0.25rem;

      input {
        background: none;
        color: ${(props) => props?.theme?.body};
        border: none;
        outline: none;
        padding: 0.5rem;
        font-family: var(--ff-subtitle);
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-direction:column;
        gap:1rem;
        align-items:flex-start;
        padding:.75rem 1rem;
        ._rightHeaderCtr{
            flex-direction:column;
        width:fit-content;
        align-items:flex-start;
        margin-right:auto;
        }
      `}
  }
`;

export default LiveActivityTable;
