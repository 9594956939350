import { StyledCommonCard } from "components/Card";
import styled from "styled-components";

export const GraphResponsiveStyledCard = styled(StyledCommonCard)`
  padding: 0rem;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.00) 100%), #FFF !important;
  ${({ theme }) => theme.mediaWidth.upToMedium`
       margin-top:34px;
  `}
`;

export const DataTableWrapper = styled.div`
  min-height: 425px;
  overflow: hidden;
  overflow-x: auto;
  position: relative;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    width: 10px;
    height: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.disabledVar3};
  }
  table {
    border-collapse: collapse !important;
    border-spacing: 0px !important;
  }
  ._table {
    width: 100%;
    font-family: var(--ff-subtitle);
  }
  ._headings {
    border-top: 1px solid ${(props) => props?.theme?.disabledVar3};
    border-bottom: 1px solid ${(props) => props?.theme?.disabledVar3};
    font-family: var(--ff-title);
    display: flex;
  }
  ._heading {
    flex: 1;
    min-width: 125px;
    white-space: nowrap;
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;

    &:nth-of-type(2) {
      min-width: 250px;
      justify-content: initial;
    }
    &:nth-of-type(1) {
      min-width: initial;
      flex: 0;
      min-width: 50px;
    }
    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
    
        justify-content:center;
        &:nth-of-type(1), &:nth-of-type(2) {
          position:sticky;
          left:0;
          top:0;
          z-index:10;
            background:${(props) => props?.theme?.bgModal};
    }
    &:nth-of-type(2){
     min-width: 200px;
         border-right: 1px solid ${(props) => props?.theme?.disabledVar3};
    }
      `}
  }
  ._tableBody {
    ${(props) => props?.theme?.flexColumnNoWrap};
    margin: 0;
  }
  table {
    border-collapse: collapse !important;
    border-spacing: 0px !important;
    padding: 0;
    margin: 0;
  }

  ._tableData {
    display: flex;
    cursor: pointer;
    min-height: 50px;

    &:nth-last-of-type(1) {
      td {
        padding-bottom: 2rem;
      }
    }
    ._dataCell {
      flex: 1;
      min-width: 125px;
      display: flex;
      align-items: center;
      padding: 0.5rem;
      &:nth-of-type(1) {
        flex: 0;
        min-width: 50px;
      }
      &:nth-of-type(2) {
        min-width: 250px;
        justify-content: initial;
      }
      .currencyLogo {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.15rem;
      }
      .profileName {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .profileImg {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
        }
        span {
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      span {
        padding-left: 0.5rem;
      }
      //@media quries : mobile screens
      ${({ theme }) => theme.mediaWidth.upToLarge`
       span {
        display:flex;
        align-items:center;
        justify-content:center;
        padding:0;
      }
      justify-content:center;
        &:nth-of-type(1), &:nth-of-type(2) {
          position:sticky;
          left:0;
          top:0;
              z-index:10;
          background:${(props) => props?.theme?.bgModal};

    }
    &:nth-of-type(2){
     min-width: 200px;
              border-right: 1px solid ${(props) => props?.theme?.disabledVar3};
    }
      `}
    }
  }
`;
