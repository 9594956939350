import axios from "axios";
import ContentCardCtr from "components/ContentCard";
import DataTable from "./DataTable";
import { DropDownCard } from "components/DropDown";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  ForwardedRef,
} from "react";

import { AlertCircle, ArrowLeft, ArrowRight, Search } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionsTable from "components/Skeletons/CollectionsPage/SKLTCollectionsTable";
import { ListButtonWrapper } from "pages/YootsDashboard/styled";
import { ChainType, TimeWindow } from "types";
import { SmallOptionButton } from "components/Button";
import TopHeaderDyanmicRightComponent from "components/HeaderComponent/TopHeaderDynamicRightComponent";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import { GenericImageWrapper } from "components/Logo";
import { getChainTypeLogo } from "utils/logo";
import { HorizontalDivider } from "components/Divider/inde";
import { useCollectionOverlap } from "data/yoots/CollectionsOverlap";
import { useZettaMutualCollections } from "data/yoots/zetta/MutualCollections";
import { useRektDogsProfitLeaderboard } from "data/yoots/rektdogsProfit";
import { CSVLink, CSVDownload } from "react-csv";
import assets from "assets";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { useAppState } from "Constexts/app.context";
import YearMonthPickerComponent from "components/DateTime/YearMonthPickerComponent";
import { log } from "console";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// import { DateRangePicker } from 'rsuite';

const CollectionsTableNav = ({ results, page, setPage }: any) => {
  const firstPage = () => {
    if (page > 1) setPage(1);
  };
  const prevPage = () => {
    if (page > 1) setPage((prev: number) => (prev -= 1));
  };

  const nextPage = () => {
    if (page < results.totalPages) setPage((prev: number) => (prev += 1));
  };
  const lastPage = () => {
    if (page < results.totalPages) setPage(results.totalPages);
  };

  const pagesArray = Array(results.totalPages).map((_, index) => index + 1);
  return results?.totalPages > 0 ? (
    <div className="_navs">
      <button className="_staticNavs" onClick={firstPage}>
        First
      </button>
      <nav className="_tableNav">
        <button onClick={prevPage} disabled={page === 0} className="_navBtn">
          <ArrowLeft size={"1rem"} />
        </button>
        {/* Removed isPreviousData from PageButton to keep button focus color instead */}

        <button className="_navPageBtn">
          Page {page} of {results.totalPages}
        </button>

        <button
          onClick={() => nextPage()}
          disabled={page === results.totalPages}
          className="_navBtn"
        >
          <ArrowRight size={"1rem"} />
        </button>
      </nav>
      <button className="_staticNavs" onClick={lastPage}>
        Last
      </button>
    </div>
  ) : null;
};

const ProfitLeaderBoard = () => {
  //constants
  const { currency } = useCurrencyUnit();
  const collectionData = useCollectionOverlap();
  //states

  const SORT_DIR = { ASEC: "asc", DESC: "desc" };

  const SORT_BY = {
    PROFIT: "approx_profitability_usd",
    SOLD: "count_sold",
    BOUGHT: "count_bought",
    SPENT: "volume_usd_bought",
    SOLD_VOLUME: "volume_usd_sold",
  };
  const [tableData, setTableData] = useState<any>();
  const [dloadData, setDloadData] = useState<any>();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("volume_usd_bought");
  const [sortDir, setSortDir] = useState<string | undefined>("desc");
  const [query, setQuery] = useState(""); //page query endpoint
  const [currentTime, setCurrentTime] = useState(2);
  const [category, setCategory] = useState({
    id: 0,
    name: "All",
  });

  const [AvailableCategories, setAvailableCategories] = useState([]);
  const [chainType, setChainType] = useState(ChainType.polygon);
  const { isMobile } = useMatchBreakpoints();
  const [dataToggle, setDataToggle] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [timeWindow, setTimeWindow] = useState(TimeWindow.all);

  const [selectedCustomTime, setSelectedCustomTime] = useState<
    { year: number; month: number } | undefined
  >(undefined);

  const onCalendarChange = (year: number, month: number) => {
    setSelectedCustomTime({ year: year, month: month });
  };

  const { mainCollection, setMainCollection } = useAppState();

  const [startDatea, setStartDatea] = useState<Date | undefined>(undefined);
  const [endDatea, setEndDatea] = useState<Date | undefined>(undefined);

  let profileID: any;

  if (mainCollection === "aps") {
    profileID = "0x419e82D502f598Ca63d821D3bBD8dFEFAf9Bbc8D";
  } else if (mainCollection === "donsy") {
    profileID = "0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB";
  }
  function formatDateToISOString(date: Date): string {
    return date.toISOString();
  }

  function isDateWithinRange(dateStr: string, start: Date, end: Date): boolean {
    const date = new Date(dateStr);
    return date >= start && date <= end;
  }

  function isDateInSpecifiedYearAndMonth(
    dateStr: string,
    year: number,
    month: number
  ): boolean {
    const date = new Date(dateStr);
    return date.getFullYear() === year && date.getMonth() === month;
  }

  function isDateBetweenTwoDates(
    dateStr: string,
    startDateStr: any,
    endDateStr: any
  ): boolean {
    const date = new Date(dateStr);
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    return date >= startDate && date <= endDate;
  }
  //callbacks
  const fetchData = useCallback(async () => {
    // let ress = await axios.get(
    //   process.env.REACT_APP_API_URL1 +
    //     `/aggregations/tradeLeaderboard?duration=${currentTime}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDir}&timeline=${dataToggle}`
    // );
    // console.log(ress.data);
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/ape/traderboard`
    );
    let flattenedArray = await res?.data?.records?.flat(Infinity);
    flattenedArray = await Promise.all(flattenedArray);

    let data = await flattenedArray
      ?.filter((i: any) => i.contract_address === profileID?.toLowerCase())
      .map(async (item: any, index: any) => {
        // const ensName = await FetchENSName(item?.address);
        return { ...item };
      });

    let dataq = await Promise.all(data);

    if (selectedCustomTime === undefined) {
      if (timeWindow === "1M") {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 3);

        const formattedStart = formatDateToISOString(startDate);
        const formattedEnd = formatDateToISOString(endDate);
        console.log(formattedStart, formattedEnd);

        dataq = dataq.filter((item) =>
          isDateWithinRange(item.sold_date, startDate, endDate)
        );
        console.log(dataq, "3m");
      } else if (timeWindow === "2M") {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 6);

        const formattedStart = formatDateToISOString(startDate);
        const formattedEnd = formatDateToISOString(endDate);
        console.log(formattedStart, formattedEnd);

        dataq = dataq.filter((item) =>
          isDateWithinRange(item.sold_date, startDate, endDate)
        );
        console.log(dataq, "6m");
      } else if (timeWindow === "3M") {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 12);

        const formattedStart = formatDateToISOString(startDate);
        const formattedEnd = formatDateToISOString(endDate);
        console.log(formattedStart, formattedEnd);

        dataq = dataq.filter((item) =>
          isDateWithinRange(item.sold_date, startDate, endDate)
        );
        console.log(dataq, "12m");
      }
    }

    if (startDatea && endDatea) {
      const endDate = new Date(endDatea);
      const startDate = new Date(startDatea);
      // startDate.setMonth(endDate.getMonth() - 3);

      const formattedStart = formatDateToISOString(startDate);
      const formattedEnd = formatDateToISOString(endDate);
      console.log(formattedStart, formattedEnd);

      dataq = dataq.filter((item) =>
        isDateBetweenTwoDates(item.sold_date, startDate, endDate)
      );
      console.log(startDate, endDate, "dfdfd", formattedStart);

      console.log(dataq, "cosromm");
    }

    if (sortBy) {
      dataq = dataq?.sort((a: any, b: any) => {
        if (sortDir === "desc") {
          return b[sortBy] - a[sortBy];
        } else {
          return a[sortBy] - b[sortBy];
        }
      });
    }

    setTableData(dataq);
  }, [
    currentTime,
    page,
    query,
    sortBy,
    sortDir,
    dataToggle,
    mainCollection,
    selectedCustomTime,
    timeWindow,
    startDatea,
    endDatea,
  ]);
  const dd = () => {
    setSelectedCustomTime(undefined);
  };

  useEffect(() => {
    dd();
  }, [timeWindow]);

  const fetchDataDownload = useCallback(async () => {
    // let res = await axios.get(
    //   process.env.REACT_APP_API_URL1 +
    //     `/aggregations/tradeLeaderboard?duration=${currentTime}&page=${1}&sortBy=${sortBy}&sortDirection=${sortDir}&timeline=${dataToggle}&dload=true`
    // );
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/ape/traderboard`
    );
    let flattenedArray = await res?.data?.records?.flat(Infinity);
    flattenedArray = await Promise.all(flattenedArray);

    let data = await flattenedArray
      ?.filter((i: any) => i.contract_address === profileID?.toLowerCase())
      .map(async (item: any, index: any) => {
        // const ensName = await FetchENSName(item?.address);
        return { ...item };
      });

    let dataq = await Promise.all(data);

    if (timeWindow) {
      if (timeWindow === "1M") {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 3);

        const formattedStart = formatDateToISOString(startDate);
        const formattedEnd = formatDateToISOString(endDate);
        console.log(formattedStart, formattedEnd);

        dataq = dataq.filter((item) =>
          isDateWithinRange(item.sold_date, startDate, endDate)
        );
        console.log(dataq, "3m");
      } else if (timeWindow === "2M") {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 6);

        const formattedStart = formatDateToISOString(startDate);
        const formattedEnd = formatDateToISOString(endDate);
        console.log(formattedStart, formattedEnd);

        dataq = dataq.filter((item) =>
          isDateWithinRange(item.sold_date, startDate, endDate)
        );
        console.log(dataq, "3m");
      } else if (timeWindow === "3M") {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 12);

        const formattedStart = formatDateToISOString(startDate);
        const formattedEnd = formatDateToISOString(endDate);
        console.log(formattedStart, formattedEnd);

        dataq = dataq.filter((item) =>
          isDateWithinRange(item.sold_date, startDate, endDate)
        );
        console.log(dataq, "12m");
      }
    }

    if (startDatea && endDatea) {
      const endDate = new Date(endDatea);
      const startDate = new Date(startDatea);
      // startDate.setMonth(endDate.getMonth() - 3);

      const formattedStart = formatDateToISOString(startDate);
      const formattedEnd = formatDateToISOString(endDate);
      console.log(formattedStart, formattedEnd);

      dataq = dataq.filter((item) =>
        isDateBetweenTwoDates(item.sold_date, startDate, endDate)
      );
      console.log(startDate, endDate, "dfdfd", formattedStart);

      console.log(dataq, "cosromm");
    }

    // if (selectedCustomTime) {
    //   const endDate = new Date();
    //   const startDate = new Date();
    //   startDate.setMonth(endDate.getMonth() - 3);

    //   const formattedStart = formatDateToISOString(startDate);
    //   const formattedEnd = formatDateToISOString(endDate);
    //   console.log(formattedStart, formattedEnd);

    //   dataq = dataq.filter((item) =>
    //     isDateInSpecifiedYearAndMonth(
    //       item.sold_date,
    //       selectedCustomTime.year,
    //       selectedCustomTime.month
    //     )
    //   );
    //   console.log(dataq, "cosromm");
    // }

    // if (sortBy) {
    //   dataq = dataq?.sort((a:any, b:any) => {
    //     if (sortDir === "desc") {
    //       return b[sortBy] - a[sortBy];
    //     } else {
    //       return a[sortBy] - b[sortBy];
    //     }
    //   });
    // }

    setDloadData(dataq);
  }, [
    currentTime,
    page,
    query,
    sortBy,
    sortDir,
    dataToggle,
    dloadData,
    mainCollection,
    selectedCustomTime,
    timeWindow,
    startDatea,
    endDatea,
  ]);

  let PolygonTableData =
    tableData &&
    tableData?.map((item: any, index: number) => {
      return {
        no: index + 1,
        wallet: item?.address,
        sold: item?.count_sold,
        bought: item?.count_bought,
        spent_in_usd: item?.volume_usd_bought,
        spent_in_eth: item?.volume_eth_bought,
        spent_in_matic: item?.volume_matic_bought,
        soldVolume_in_usd: item?.volume_usd_sold,
        soldVolume_in_eth: item?.volume_eth_sold,
        soldVolume_in_matic: item?.volume_matic_sold,
        nftHold:
          item?.nfts_held_currently < 0
            ? item?.nfts_held_currently * -1
            : item?.nfts_held_currently,
        username: item?.username,
      };
    });

  let ExportData =
    dloadData &&
    dloadData?.map((item: any, index: number) => {
      return {
        no: index + 1,
        wallet: item?.address,
        sold: item?.count_sold,
        bought: item?.count_bought,
        spent_in_usd: item?.volume_usd_bought,
        spent_in_eth: item?.volume_eth_bought,
        spent_in_matic: item?.volume_matic_bought,
        soldVolume_in_usd: item?.volume_usd_sold,
        soldVolume_in_eth: item?.volume_eth_sold,
        soldVolume_in_matic: item?.volume_matic_sold,
        nftHold:
          item?.nfts_held_currently < 0
            ? item?.nfts_held_currently * -1
            : item?.nfts_held_currently,
        twitter: item?.username ? `https://twitter.com/${item?.username}` : "",
      };
    });

  const sortItem = ({ sort_by }: { sort_by: string }) => {
    console.log(sort_by);
    setSortBy(sort_by);
    setSortDir((prev) =>
      prev === SORT_DIR.ASEC ? SORT_DIR.DESC : SORT_DIR.ASEC
    );
  };

  const columns = [
    {
      id: 1,
      heading: "",
      value: "no",
    },

    {
      id: 2,
      heading: "Wallet",
      value: "wallet",
      // link: "wallet",
      username: "username",
    },
    {
      id: 3,
      heading: "Sold",
      value: "sold",
      action: () => {
        sortItem({ sort_by: SORT_BY.SOLD });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.SOLD,
    },
    {
      id: 4,
      heading: "Bought",
      value: "bought",
      action: () => {
        sortItem({ sort_by: SORT_BY.BOUGHT });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.BOUGHT,
    },
    {
      id: 5,
      heading: "Bought Volume",
      value:
        currency.symbol === "USD"
          ? "spent_in_usd"
          : currency.symbol === "ETH"
          ? "spent_in_eth"
          : "spent_in_matic",
      action: () => {
        sortItem({ sort_by: SORT_BY.SPENT });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.SPENT,
    },
    {
      id: 6,
      heading: "Sell Volume",
      value:
        currency.symbol === "USD"
          ? "soldVolume_in_usd"
          : currency.symbol === "ETH"
          ? "soldVolume_in_eth"
          : "soldVolume_in_matic",
      action: () => {
        sortItem({ sort_by: SORT_BY.SOLD_VOLUME });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.SOLD_VOLUME,
    },
  ];

  useEffect(() => {
    fetchDataDownload();
    fetchData();
  }, [
    fetchData,
    mainCollection,
    selectedCustomTime,
    timeWindow,
    startDatea,
    endDatea,
  ]);

  // interface ExampleCustomInputProps {
  //   value: string;
  //   onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  // }

  // const ExampleCustomInput = forwardRef<
  //   HTMLButtonElement,
  //   Partial<ExampleCustomInputProps>
  // >(({ value, onClick }, ref) => (
  //   <button className="example-custom-inputa" onClick={onClick} ref={ref}>
  //     {/* {value} */}

  //     <GenericImageWrapper src={assets.icons.calendarIcon} />
  //   </button>
  // ));

  interface ExampleCustomInputProps {
    value: string;
    onClick: any;
  }

  const ExampleCustomInput = forwardRef<
    HTMLButtonElement,
    Partial<ExampleCustomInputProps>
  >(({ value, onClick }, ref) => (
    <div className="example-custom-input" onClick={onClick}>
      

      <GenericImageWrapper src={assets.icons.calendarIcon} />
    </div>
  ));
  let time 

  if(isMobile){
    time = [
      // TimeWindow.oneMonth,
      // TimeWindow.twoMonth,
      // TimeWindow.threeMonth,
      TimeWindow.custom,
    ]

  }else{
    time = [
      TimeWindow.oneMonth,
      TimeWindow.twoMonth,
      TimeWindow.threeMonth,
      TimeWindow.custom,
    ]


  }

  return (
    <CollectionsTableCtr>
      <TopHeaderDyanmicRightComponent
        title="Trade Leaderboard"
        tooltip="Trade Leaderboard"
        rightComponent={
          <div className="rightComponent">
            {ExportData && (
              <CSVLink
                data={ExportData}
                filename={`TradeLeaderboard-${new Date()?.toDateString()}.csv`}
                className="exportBtn"
                target="_blank"
              >
                <img src={assets.icons.downloadIcon} alt="download" />
              </CSVLink>
            )}

            <ListButtonWrapper>
              {time?.map((option, index) => {
                return (
                  <SmallOptionButton
                    key={index}
                    active={timeWindow === option}
                    onClick={() => {
                      if (option == "custom") {
                        setTimeWindow(option);
                      } else {
                        setTimeWindow(option);
                        setStartDatea(undefined);
                        setEndDatea(undefined);
                      }
                    }}
                  >
                    {option === "custom" ? (
                      // <YearMonthPickerComponent
                      //   year={
                      //     selectedCustomTime
                      //       ? selectedCustomTime.year
                      //       : dayjs().year()
                      //   }
                      //   month={
                      //     selectedCustomTime
                      //       ? selectedCustomTime.month
                      //       : dayjs().month()
                      //   }
                      //   onChange={onCalendarChange}
                      //   isMobileView={isMobile}
                      // />
                      // <DatePicker
                      //   selected={startDatea}
                      //   onChange={(date: Date) => {
                      //     setStartDatea(date);
                      //   }}
                      //   customInput={<ExampleCustomInput />}
                      // />
                      <div className="sms">
                        {isMobile ? (
                          <div className="mobils">
                            <DatePicker
                              selected={startDatea}
                              onChange={(date: any) => setStartDatea(date)}
                              selectsStart
                              startDate={startDatea}
                              endDate={endDatea}
                              placeholderText="Start"
                              customInput={<ExampleCustomInput />}
                            />
                            <DatePicker
                              selected={endDatea}
                              onChange={(date: any) => setEndDatea(date)}
                              selectsEnd
                              startDate={startDatea}
                              endDate={endDatea}
                              minDate={startDatea}
                              placeholderText="End"
                              customInput={<ExampleCustomInput />}
                            />
                          </div>
                        ) : (
                          <div className="pcs">
                            <DatePicker
                              selected={startDatea}
                              onChange={(date: any) => setStartDatea(date)}
                              selectsStart
                              startDate={startDatea}
                              endDate={endDatea}
                              placeholderText="Start"
                            />
                            <DatePicker
                              selected={endDatea}
                              onChange={(date: any) => setEndDatea(date)}
                              selectsEnd
                              startDate={startDatea}
                              endDate={endDatea}
                              minDate={startDatea}
                              placeholderText="End"
                            />
                          </div>
                        )}
                       
                      </div>
                    ) : (
                      option
                    )}
                  </SmallOptionButton>
                );
              })}
            </ListButtonWrapper>
          </div>
        }
      />
      {PolygonTableData ? (
        <DataTable
          column={columns}
          data={PolygonTableData ? PolygonTableData : []}
          sortItemLabel={sortBy}
        />
      ) : (
        <SKLTCollectionsTable />
      )}
      {tableData?.data?.length > 0 ? (
        <CollectionsTableNav
          results={tableData}
          page={page}
          setPage={setPage}
          sortItemLabel={sortBy}
        />
      ) : null}
      <HorizontalDivider />
    </CollectionsTableCtr>
  );
};

const CollectionsTableCtr = styled.div`
  gap: 0;
  .mobils {
    display: flex;
    gap: 10px;

  }
  .pcs{
    display: flex;
    gap: 10px;

  }
  .sms {
    display: flex !important;
    input {
      width: 80px !important;
      height: 25px !important;
      background: transparent !important;
      border: none !important;
      outline: none !important;
      color: #fff !important;
      font-size: 0.6rem !important;
      text-align: center !important;
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-size: 1rem !important;

        color: #fff;
        /* Firefox */
      }
    }
  }
  .example-custom-inputa {
    background: transparent !important;
    border: none !important;
  }
  .sms {
    position: relative;
    z-index: 900 !important;
  }
  .react-datepicker-popper {
    z-index: 900 !important;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    


      `}
  }

  .eWMZOX button:last-child {
    border-right: none !important;
    border-radius: none !important;
  }

  .rightComponent {
    display: flex;
    align-items: center;
    gap: 1rem;
    .exportBtn {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      background-color: #000000;
      color: #919191;
      border: 1px solid #474747;
      border-radius: 8px;
      padding: 8px 10px;
      text-align: center;
      text-decoration: none;
    }
    .exportBtn:hover {
      background-color: #444343;
      color: #fff;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 75px;
      height: 28px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #2d2f31;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
  ._navs {
    ${(props) => props?.theme?.flexRowNoWrap}
    gap:.25rem;
    width: fit-content;
    margin: 1rem;
    margin-left: auto;
    button {
      background: none;
      outline: none;
      border: none;
      color: ${(props) => props?.theme?.body};
      font-family: var(--ff-subtitle);
      display: grid;
      place-items: center;
      padding: 0.5rem;
      font-size: var(--fs-s) !important;
    }

    ._staticNavs {
      width: 70px;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
    }

    ._tableNav {
      ${(props) => props?.theme?.flexRowNoWrap}
      gap:.25rem;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
      ._navPageBtn {
        min-width: 100px;
      }
      ._navBtn {
        &:nth-of-type(1) {
          border-right: 1px solid ${(props) => props?.theme?.disabled};
        }
        &:nth-of-type(3) {
          border-left: 1px solid ${(props) => props?.theme?.disabled};
        }
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      margin:1rem auto;
      `}
  }

  ._tableHeader {
    padding: 1.25rem 1rem;
    ${(props) => props?.theme?.flexRowNoWrap}
    justify-content: space-between;
    align-items: center;
    ._rightHeaderCtr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    ._tableTitle {
      font-family: var(--ff-title);
      font-size: var(--fs-l);
      display: flex;
      gap: 0.5rem;
    }
    ._searchResultsBar {
      ${(props) => props?.theme?.flexRowNoWrap}
      align-items: center;
      gap: 0.5rem;
      border: 1px solid ${(props) => props?.theme?.white};
      width: 200px;
      padding: 0 0.5rem;
      border-radius: 0.25rem;

      input {
        background: none;
        color: ${(props) => props?.theme?.body};
        border: none;
        outline: none;
        padding: 0.5rem;
        font-family: var(--ff-subtitle);
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-direction:column;
        gap:1rem;
        align-items:flex-start;
        padding:.75rem 1rem;
        ._rightHeaderCtr{
            flex-direction:column;
        width:fit-content;
        align-items:flex-start;
        margin-right:auto;
        }
      `}
  }
`;

export default ProfitLeaderBoard;
