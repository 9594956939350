import contentFont from "./fonts/Roobert-Regular.ttf";
import subtitleFont from "./fonts/Roobert-SemiBold.ttf";
import titleFont from "./fonts/Roobert-Bold.ttf";
import GeneralSans from "./fonts/GeneralSans/GeneralSans-Regular.ttf";
import Intern500 from "./fonts/Inter/Inter-SemiBold.ttf";

//icons
import bargraphIcon from "./icons/icon-bargraph.png";
import calendarIcon from "./icons/icon-calendar.svg";
import downloadIcon from "./icons/icon-download.svg";
import ecosystemIcon from "./icons/icon-ecosystem.svg";
import filledDownArrowIcon from "./icons/icon-filled-down-arrow.svg";
import infoIcon from "./icons/icon-info.png";
import linegraphIcon from "./icons/icon-linegraph.svg";
import marketplacesIcon from "./icons/icon-marketplaces.svg";
import navMenuIcon from "./icons/icon-nav-menu.svg";
import recentIcon from "./icons/icon-recent.svg";
import searchIcon from "./icons/icon-search.svg";
import trendingUpIcon from "./icons/icon-trending-up.svg";
import verifyIcon from "./icons/icon-verify.svg";
import closeIcon from "./icons/icon-close.png";
import roundedDownloadIcon from "./icons/icon-roundedDownloadButton.svg";
import ticketsIcon from "./icons/icon-tickets.svg";
import musicIcon from "./icons/icon-music.svg";
import robotIcon from "./icons/icon-robot.svg";
import gridIcon from "./icons/icon-grid.svg";
import defiIcon from "./icons/icon-defi.svg";
import gameIcon from "./icons/icon-game.svg";
import utilIcon from "./icons/icon-util.svg";
import likeIcon from "./icons/icon-like.svg";
import entertainmentIcon from "./icons/icon-entertainment.svg";
import miscxIcon from "./icons/icon-miscx.svg";
import userIcon from "./icons/icon-user.svg";
import artIcon from "./icons/icon-art.svg";
import dailyPriceIcon from "./icons/icon-daily-price.svg";
import defaultCollectionIcon from "./icons/icon-defaultCollection.svg";
import moneyBagIcon from "./icons/icon-money-bag.svg";
import rocketIcon from "./icons/icon-rocket.svg";
import sandClockIcon from "./icons/icon-sand-clock.svg";
import thinkingEmojiIcon from "./icons/icon-thinking-emoji.svg";

//logos
import discordWhiteLogo from "./logos/logo-discord-white.svg";
import discordLogo from "./logos/logo-discord.svg";
import ethscanLogo from "./logos/logo-ethScan.svg";
import mediumWhiteLogo from "./logos/logo-medium-white.svg";
import mediumLogo from "./logos/logo-medium.svg";
import polygonLogoWithName from "./logos/logo-polygon-with-name.png";
import polygonLogoIcon from "./logos/logo-polygon.png";
import polygonLogoCircle from "./logos/logo-polyon-circled.svg";
import twitterLogo from "./logos/logo-twitter.svg";
import twitterWhiteLogo from "./logos/logo-twitter-white.svg";
import openseaLogo from "./logos/logo-opensea.svg";
import onelanetNFTLogo from "./logos/logo-oneplanetNFT.png";
import raribleLogo from "./logos/logo-rarible.png";
import magicEdenLogo from "./logos/logo-magic-eden.svg";
import nftradeLogo from "./logos/logo-nfttrade.png";
import layerELogo from "./logos/logo-surfaceboard2.svg";
import matic from "./logos/matic.svg";
import ethLogo from "./logos/logo-eth.svg";
import ethLogoIcon from "./logos/logo-eth-icon.svg";
import usdLogo from "./logos/logo-usd.svg";
import surfaceboardLogo from "./logos/logo-surfaceboard.svg";
import surfaceboardLogoCircled from "./logos/logo-surfaceboard-circled.svg";
import surfaceboardXYZLogo from "./logos/logo-surfaceboard-xyz.svg";
import instagramLogo from "./logos/logo-instagram.svg";
import linkedinLogo from "./logos/logo-linkedin.svg";
import solanaLogo from "./logos/logo-solana.svg";
import yootsLogo from "./logos/yoots.svg";
import zettaLogo from "./logos/logo-zettablock.svg";
import logok from "./logos/logok.svg";

import LinkedInW from "./logos/inkedInW.svg";
import instaW from "./logos/instaW.svg";





//images
import bg from "./imges/bg.svg";
import bgmain from "./imges/bgmain.png";
import nft1 from "./imges/nft1.png";
import nft2 from "./imges/nft2.jpg";
import top1 from "./imges/top1.png";
import top2 from "./imges/top2.png";
import top3 from "./imges/top3.png";
import top4 from "./imges/top5.png";
import blog1 from "./imges/blog1.svg";
import blog2 from "./imges/blog2.svg";
import bgline1 from "./imges/bgline.png";
import bgline2 from "./imges/bglinewithstar.png";
import starbg from "./imges/starbg.png";
import bgLogin from "./imges/bgLogin.svg";
import bgYoots from "./imges/bgYoots.png";
import bgSBDashboard from "./imges/bgSBDashboard.png";
import bgYoots2 from "./imges/loginbg.png";
import bgSignupBanner from "./imges/bgSignupBanner.svg";
import bgo from "./imges/bgo.png";

import rightCard from "./imges/rightCard.png";

import leftCard from "./imges/leftCard.png";

const fonts = {
  contentFont,
  subtitleFont,
  titleFont,
  GeneralSans,
  Intern500,
};
const icons = {
  bargraphIcon,
  calendarIcon,
  downloadIcon,
  ecosystemIcon,
  filledDownArrowIcon,
  infoIcon,
  linegraphIcon,
  marketplacesIcon,
  navMenuIcon,
  recentIcon,
  searchIcon,
  trendingUpIcon,
  verifyIcon,
  closeIcon,
  roundedDownloadIcon,
  musicIcon,
  robotIcon,
  ticketsIcon,
  userIcon,
  gridIcon,
  defiIcon,
  gameIcon,
  entertainmentIcon,
  miscxIcon,
  utilIcon,
  likeIcon,
  artIcon,
  dailyPriceIcon,
  defaultCollectionIcon,
  moneyBagIcon,
  rocketIcon,
  sandClockIcon,
  thinkingEmojiIcon,
};
const logos = {
  zettaLogo,
  discordWhiteLogo,
  discordLogo,
  ethscanLogo,
  mediumWhiteLogo,
  polygonLogoWithName,
  polygonLogoIcon,
  polygonLogoCircle,
  twitterLogo,
  twitterWhiteLogo,
  openseaLogo,
  onelanetNFTLogo,
  raribleLogo,
  magicEdenLogo,
  nftradeLogo,
  layerELogo,
  matic,
  ethLogo,
  ethLogoIcon,
  usdLogo,
  surfaceboardLogo,
  instagramLogo,
  linkedinLogo,
  surfaceboardLogoCircled,
  surfaceboardXYZLogo,
  solanaLogo,
  yootsLogo,
  logok,
  LinkedInW,
  instaW
};
const images = {
  bg,
  bgmain,
  nft1,
  nft2,
  top1,
  top2,
  top3,
  bgo,
  top4,
  blog1,
  blog2,
  bgline1,
  bgline2,
  starbg,
  bgLogin,
  bgYoots,
  bgYoots2,
  bgSBDashboard,
  bgSignupBanner,
  rightCard,
  leftCard
};
const assets = {
  fonts,
  icons,
  logos,
  images,
};
export default assets;
