import axios from "axios";
import ContentCardCtr from "components/ContentCard";
import DataTable from "./DataTable";
import { DropDownCard } from "components/DropDown";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import React, { useCallback, useEffect, useState } from "react";
import { AlertCircle, ArrowLeft, ArrowRight, Search } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionsTable from "components/Skeletons/CollectionsPage/SKLTCollectionsTable";
import { ListButtonWrapper } from "pages/YootsDashboard/styled";
import { ChainType } from "types";
import { SmallOptionButton } from "components/Button";
import TopHeaderDyanmicRightComponent from "components/HeaderComponent/TopHeaderDynamicRightComponent";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import { GenericImageWrapper } from "components/Logo";
import { getChainTypeLogo } from "utils/logo";
import { HorizontalDivider } from "components/Divider/inde";
import { useCollectionOverlap } from "data/yoots/CollectionsOverlap";
import { useZettaMutualCollections } from "data/yoots/zetta/MutualCollections";
import { Console } from "console";
// import { fetchEnsAddress, fetchEnsName } from "@wagmi/core";
import { utils, providers } from "ethers";

import { useParams } from "react-router-dom";
import { useAppState } from "Constexts/app.context";

const CollectionsTableNav = ({ results, page, setPage }: any) => {
    const firstPage = () => {
        if (page > 1) setPage(1);
    };
    const prevPage = () => {
        if (page > 1) setPage((prev: number) => (prev -= 1));
    };

    const nextPage = () => {
        if (page < results.totalPages) setPage((prev: number) => (prev += 1));
    };
    const lastPage = () => {
        if (page < results.totalPages) setPage(results.totalPages);
    };

    const pagesArray = Array(results.totalPages)
        .map((_, index) => index + 1);
    return results?.totalPages > 0 ? (
        <div className="_navs">
            <button className="_staticNavs" onClick={firstPage}>
                First
            </button>
            <nav className="_tableNav">
                <button onClick={prevPage} disabled={page === 0} className="_navBtn">
                    <ArrowLeft size={"1rem"} />
                </button>
                {/* Removed isPreviousData from PageButton to keep button focus color instead */}

                <button className="_navPageBtn">
                    Page {page} of {results.totalPages}
                </button>

                <button
                    onClick={() => nextPage()}
                    disabled={page === results.totalPages}
                    className="_navBtn"
                >
                    <ArrowRight size={"1rem"} />
                </button>
            </nav>
            <button className="_staticNavs" onClick={lastPage}>
                Last
            </button>
        </div>
    ) : null;
};

const TopMutualCollectionTable = () => {
    //constants
    const { currency } = useCurrencyUnit();
    const collectionData = useCollectionOverlap();
    //states
    const [tableData, setTableData] = useState<any>();
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState(null);
    const [sortDir, setSortDir] = useState<string | undefined>();
    const [query, setQuery] = useState(""); //page query endpoint
    const [currentTime, setCurrentTime] = useState(2);
    const [category, setCategory] = useState({
        id: 0,
        name: "All",
    });

    const [AvailableCategories, setAvailableCategories] = useState([]);
    const [chainType, setChainType] = useState(ChainType.polygon)
    const { isMobile } = useMatchBreakpoints();
    const { mainCollection, setMainCollection } = useAppState();
  
    const [list, setlistData] = React.useState({});
  
  
      let profileID: any;
  
      if (mainCollection === "aps") {
        profileID = "0x419e82D502f598Ca63d821D3bBD8dFEFAf9Bbc8D";
      } else if (mainCollection === "donsy") {
        profileID = "0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB";
      }

      
    //callbacks
    // const fetchData = useCallback(async () => {
    //     let res = await axios.get(
    //         process.env.REACT_APP_API_URL +
    //         `/topCollection?duration=${currentTime}&page=${page}&search=${query}&sortBy=${sortBy}&sortDirection=${sortDir}&category=${category?.name}`
    //     );
    //     setTableData(res.data);

    //     setAvailableCategories(
    //         res.data?.category?.map((item: any, index: any) => {
    //             return {
    //                 id: index,
    //                 name: item,
    //             };
    //         })
    //     );
    //     if (category?.id > 0 && !res.data?.category?.includes(category?.name)) {
    //         setCategory({
    //             id: 0,
    //             name: "All",
    //         });
    //     }
    // }, [currentTime, category, page, query, sortBy, sortDir]);
    // console.log(tableData,"dfdfdfgdsgfd")
    // const PolygonData = useZettaMutualCollections();
    // let PolygonTableData = PolygonData?.records && PolygonData?.records?.map((item: any,index:number) => {
    //     return {
    //           no: index + 1,
    //           name: item?.collection || item?.nft_contract_address?.slice(0,6) + '...' + item?.nft_contract_address?.slice(-4),
    //           mutuals: item?.mutual_count
    //     }
    // })
    // // remove duplicates from polygonTableData
    // PolygonTableData = PolygonTableData?.filter((item:any,index:number) => {
    //     return PolygonTableData?.findIndex((item2:any) => item2?.name === item?.name) === index
    // })?.slice(0,10)
    // console.log(PolygonTableData,PolygonData?.records?.map((item:any) => item?.collection))
    // let solanaData = collectionData?.data?.data && collectionData?.data?.data?.map((item: any,index:number) => {
    //     return {
    //        no: index + 1,
    //        name: item?.nft?.name,
    //        mutuals: item?.amountOwnedOverlapCollection
    //     }
    // })?.slice(0,10)

    // 
  const [owners, setOwners] = useState(null);
  const [mutualCollections, setMutualCollections] = useState(null);


    const FetchENSName = async (address:string) => {
        try {
          const provider = new providers.JsonRpcProvider(
            "https://rpc.ankr.com/eth"
          );
          const ensName = await provider.lookupAddress(address);
          return ensName;
        } catch (error) {
          console.log(error);
          return address?.slice(0, 6) + "..." + address?.slice(-4);
        }
      };

    const FetchOwners = async () => {
        try {
          const res = await axios.get(
            `${
              process.env.REACT_APP_API_URL
            }/collection/owners/${profileID}?duration=${1}`
          );
          let data = res?.data?.data?.TopOwners?.map(async (item:any) => {
            let ensName = await FetchENSName(item?.wallet );
            
            return {
              ...item,
              ens: ensName
                ? ensName
                : item?.wallet?.slice(0, 6) + "..." + item?.wallet?.slice(-4),
            };
          });
          data = await Promise.all(data);
          setOwners(data);
          if (res?.data?.data?.TopOwners?.length > 0) {
            // FetchFinancialProfiles(
            //   res?.data?.data?.TopOwners?.slice(0, 20).map((item) => item?.wallet)
            // );
            // await delay(1000);
            FetchMutualCollections(
              res?.data?.data?.TopOwners?.slice(0, 20).map((item:any) => item?.wallet)
            );
          }
        } catch (error) {
          console.log(error);
        }
      };
      const FetchMutualCollections = async (wallets:any) => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/mutualCollections/${profileID}?wallets=${wallets}`
          );
          let data = res?.data?.data;
    
          data = data.filter((item:any, index:any) => {
            return (
              data.findIndex(
                (obj : any) => obj?.contractAddress === item?.contractAddress
              ) === index
            );
          }).filter((obj : any) => obj.contractAddres !== profileID)
    
          setMutualCollections(data.slice(0, 10));
        } catch (error) {
          console.log(error);
        }
      };

 
    

    const columns = [
        {
            id: 1,
            heading: "",
            value: "no",
        },

        {
            id: 2,
            heading: "Collection Name",
            value: "name",
            link: "contractAddress",
            logo: "icon",
        },
        {
            id: 3,
            heading: "Mutuals",
            value: "tokensCount",
        },

    ];
    const delay = (ms:any) => new Promise((resolve) => setTimeout(resolve, ms));

    useEffect(() => {
        async function fetchData() {
            await delay(2000);
            FetchOwners();
          }
    //   FetchOwners();
    fetchData()
    // fetchData();


    }, [mainCollection]);

    return (
        <CollectionsTableCtr>
            <TopHeaderDyanmicRightComponent
                title="Top Mutual Collection"
                tooltip="Top other NFT projects Polygon Ape Holders are involved in"
            />
            {mutualCollections ? (
                <DataTable
                    column={columns}
                    data={mutualCollections}
                    sortItemLabel={sortBy}
                />
            ) : (
                <SKLTCollectionsTable />
            )}
            <HorizontalDivider />
           
        </CollectionsTableCtr>
    );
};

const CollectionsTableCtr = styled.div`
  gap: 0;
  ._navs {
    ${(props) => props?.theme?.flexRowNoWrap}
    gap:.25rem;
    width: fit-content;
    margin: 1rem;
    margin-left: auto;
    button {
      background: none;
      outline: none;
      border: none;
      color: ${(props) => props?.theme?.body};
      font-family: var(--ff-subtitle);
      display: grid;
      place-items: center;
      padding: 0.5rem;
      font-size: var(--fs-s) !important;
    }

    ._staticNavs {
      width: 70px;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
    }

    ._tableNav {
      ${(props) => props?.theme?.flexRowNoWrap}
      gap:.25rem;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
      ._navPageBtn {
        min-width: 100px;
      }
      ._navBtn {
        &:nth-of-type(1) {
          border-right: 1px solid ${(props) => props?.theme?.disabled};
        }
        &:nth-of-type(3) {
          border-left: 1px solid ${(props) => props?.theme?.disabled};
        }
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      margin:1rem auto;
      `}
  }

  ._tableHeader {
    padding: 1.25rem 1rem;
    ${(props) => props?.theme?.flexRowNoWrap}
    justify-content: space-between;
    align-items: center;
    ._rightHeaderCtr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    ._tableTitle {
      font-family: var(--ff-title);
      font-size: var(--fs-l);
      display: flex;
      gap: 0.5rem;
    }
    ._searchResultsBar {
      ${(props) => props?.theme?.flexRowNoWrap}
      align-items: center;
      gap: 0.5rem;
      border: 1px solid ${(props) => props?.theme?.white};
      width: 200px;
      padding: 0 0.5rem;
      border-radius: 0.25rem;

      input {
        background: none;
        color: ${(props) => props?.theme?.body};
        border: none;
        outline: none;
        padding: 0.5rem;
        font-family: var(--ff-subtitle);
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-direction:column;
        gap:1rem;
        align-items:flex-start;
        padding:.75rem 1rem;
        ._rightHeaderCtr{
            flex-direction:column;
        width:fit-content;
        align-items:flex-start;
        margin-right:auto;
        }
      `}
  }
`;

export default TopMutualCollectionTable;
