import { useAppState } from "Constexts/app.context";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { TEXT } from "theme/texts";

type propsTabview = {
  data: {
    label: string;
    value: string;
  }[];
  value: string;
  onClick: (value: string) => void;
  wrapperstyle?: string;
};

const Tabview = ({ data, value, onClick, wrapperstyle }: propsTabview) => {

  const { mainCollection, setMainCollection } = useAppState();


  let profileID : any; 
  let profileID2 :any;

  if(mainCollection === "all"){
   profileID = '0x419e82D502f598Ca63d821D3bBD8dFEFAf9Bbc8D';
   profileID2 = '0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB';



  } else if(mainCollection === "donsy"){
   profileID2 = '0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB';


  }
  return (
    <TabViewWrapper>
      {data.map((element) => {
        return (
          <StyledNavLink
          mainCollection = {mainCollection}
            active={element.value === value}
            onClick={() => onClick(element.value)}
          >
            <TEXT.SmallHeader
              padding={'1rem'}
              fontWeight={500}
              style={{ whiteSpace: "nowrap", fontSize: "1rem" }}
            >
              {element.label}
            </TEXT.SmallHeader>
            <div className="_bottom" />
          </StyledNavLink>
        );
      })}
    </TabViewWrapper>
  );
};

const StyledNavLink = styled.div<{ active?: boolean, mainCollection?: any }>`
  cursor: pointer;
  color: ${({ active, theme }) => (active ? theme.body : theme.disabledVar2)};
  text-decoration: none;
  align-items: center;
  ${({ active, theme ,mainCollection }) =>
    active
      ? `
          color:${theme.body};
    ._bottom {
      width: auto;
      height: 4px;
      background: ${ mainCollection === "all" ? "#000" : mainCollection === "aps" ? " #8047E2" : "#80755F"};
     }`
      : ""}
`;

const TabViewWrapper = styled.div`
  display: flex;
  gap: 2rem;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  /* margin-left: 126px; */
  ${({ theme }) => theme.mediaWidth.upToMedium`
   gap: 0.4rem;
  `}
`;

export default Tabview;
