import assets from "assets";
import { SmallOptionButton } from "components/Button";
import LineAreaChart from "components/Chart/LineAreaChart";
import { AutoColumn } from "components/Column";
import { HorizontalDivider } from "components/Divider/inde";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { GenericImageWrapper } from "components/Logo";
import Row from "components/Row";
import { TabularNumsText } from "components/shared";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { useCurrencyUnit } from "store/user/hooks";
import { TEXT } from "theme/texts";
import {
  ChainType,
  ChartDayData,
  GenericChartSeries,
  TimeWindow,
  TradeActionWindow,
} from "types";
import { unixToDate } from "utils/date";
import { formatAmount } from "utils/numbers";
import { getScrollToElementPath } from "utils/path";
import {
  ChartTopRightSectionWrapper,
  ChartWrapper,
  ListButtonWrapper,
} from "./styled";
import { getChainTypeLogo } from "utils/logo";
import { useSolanaSalesData } from "data/yoots/y00tsSales";
import { useSolanaGraphData } from "data/yoots/y00tsGraphData";
import { useAppState } from "Constexts/app.context";
import axios from "axios";

const twitterShareScrollPath = getScrollToElementPath(null, null);
const snapshotDownloadIconId = "snapshot-floor-avg-price-download-icon";
const snapshotBrandLogoId = "snapshot-floor-avg-price-brand-logo";

const ButtonList = ({
  timeWindow,
  setTimeWindow

}: {
  timeWindow: TimeWindow;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
}) => {
  return (
    <ChartTopRightSectionWrapper>
      <ListButtonWrapper>
        {[TimeWindow.threeMonth, TimeWindow.sixMonth, TimeWindow.oneYear].map(
          (option) => {
            return (
              <SmallOptionButton
                active={timeWindow === option}
                onClick={() => setTimeWindow(option)}
              >
                {option === "custom" ? (
                  <GenericImageWrapper
                    src={assets.icons.calendarIcon}
                    size="18px"
                  />
                ) : (
                  option
                )}
              </SmallOptionButton>
            );
          }
        )}
      </ListButtonWrapper>
    </ChartTopRightSectionWrapper>
  );
};

export type graphProps = {
  chartData: any;
  refGraph: React.RefObject<HTMLDivElement> | any;
};

function NewActivtyMap({ chartData, refGraph }: graphProps) {
  const theme = useTheme();
  const isBarGraph = false;
  const { isMobile } = useMatchBreakpoints();
  const [volumeHover, setVolumeHover] = useState<number | undefined>();
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
  const [leftLabel, setLeftLabel] = useState<string | undefined>();
  const [rightLabel, setRightLabel] = useState<string | undefined>();
  const [timeWindow, setTimeWindow] = useState(TimeWindow.threeMonth);
  const [avgFloorPrice, setAvgFloorPrice] = useState(0);
  const [allData, setAllData] = useState<any[]>([]);
  const { currency } = useCurrencyUnit();
  const [chainType, setChainType] = useState(ChainType.polygon);
  const [tradeActionWindow, setTradeActionWindow] = useState(
    TradeActionWindow.volume
  );
  // const solanaData = useSolanaGraphData();
  // value is 15000 for now
  // generate array with date which increases till 15000

  const downloadScreenshot = useTakeScreenshot({
    ref: refGraph,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });

  useEffect(() => {
    setLiquidityHover(undefined);
    setVolumeHover(undefined);
  }, []);

  // useEffect(() => {
  //   let data = chartData?.map((day: any) => {
  //     return {
  //       ...day,
  //       min: currency?.symbol === "ETH" ? day?.min_in_eth : currency?.symbol === "USD" ? day?.min_in_usd : day?.min,
  //       avg: currency?.symbol === "ETH" ? day?.avg_in_eth : currency?.symbol === "USD" ? day?.avg_in_usd : day?.avg,
  //       max: currency?.symbol === "ETH" ? day?.max_in_eth : currency?.symbol === "USD" ? day?.max_in_usd : day?.max,
  //     }
  //   })
  //   setAllData(data);
  // }, [chartData, currency?.symbol]);

  const FilterDate = (data: ChartDayData[], timeWindow: TimeWindow) => {
    let filteredData: any[] = [];
    switch (timeWindow) {
      case TimeWindow.threeMonth:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 7776000
        );
        break;
      case TimeWindow.sixMonth:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 15552000
        );
        break;
      case TimeWindow.oneYear:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 31104000
        );
        break;
      default:
        filteredData = data;
        break;
    }
    const avg = filteredData.find((day) => day.avg !== 0);
    setAvgFloorPrice(avg?.avg);
    return filteredData;
  };
  

  console.log(chartData,"dsfdsgdfhgd");
  /**
   * @notice
   * @dev 'formattedChartSeries'  must change according to requirement
   * Add according requirement (This will plot line graph with provided color and data)
   */
  const formattedChartSeries: GenericChartSeries[] = [
    // { dataKey: "valueOpensea", name: "Floor", color: "#0057FF" },
    { dataKey: "valueOpensea", name: "Count", color: "#1FD57B" },
  ];


  const { mainCollection, setMainCollection } = useAppState();
  const [liveActivity, setliveActivity] = useState([]);

  let profileID: any;

  if (mainCollection === "aps") {
    profileID = "ALL";
  } else if (mainCollection === "donsy") {
    profileID = "DONSY";
  }


  const fetchLiveActivity = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/ape/activity?type=${profileID}`
      );

      setliveActivity(await res?.data?.records);
      console.log(liveActivity, "fgdgdfgdhfghfg Acyverse",res?.data?.records);
    } catch (error) {
      console.log(error);
    }
  };
  // const formattedChartData = useMemo(() => {
  //   if (liveActivity?.length > 0) {
      const formattedChartData=  liveActivity?.map((day: any) => {
        return {
          time: day.day,
          valueOpensea: Number(day.cumulative_count),
          valueMagicEden: Number(day.cumulative_count),
          valueFractal: Number(day.cumulative_count),
        };
      });
  //   } else {
  //     return [];
  //   }
  // }, [chartData,mainCollection]);

  useEffect(() => {
    
    fetchLiveActivity();
  }, [mainCollection]);

  return (
    <>
      <GraphTableTopHeader
        title={"Activity Map"}
        tooltip={`Activity map of the users based on their transactions on chain`}
        downloadScreenshot={downloadScreenshot}
        isMobileView={isMobile}
        snapshotBrandLogoId={snapshotBrandLogoId}
        snapshotDownloadIconId={snapshotDownloadIconId}
        tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights:  @LayerEhq here: ${twitterShareScrollPath} `}
      />
      <HorizontalDivider />
      <ChartWrapper>
        <LineAreaChart
          data={formattedChartData}
          // Change this according to dataset
          series={formattedChartSeries}
          yAxisLabel="Activity"
          setValue={setVolumeHover}
          setLabel={setRightLabel}
          value={volumeHover}
          label={rightLabel}
          activeWindow={timeWindow}
          // topRight={
             
          //   <ButtonList
          //     timeWindow={timeWindow}
          //     setTimeWindow={setTimeWindow}
          //   />
          // }
          // topLeft={
          //  <div style={{opacity:"0"}}></div>
          // }
         
        />
      </ChartWrapper>
    </>
  );
}

export default NewActivtyMap;
