import { AutoRow, ResponsiveRow } from "components/Row";
import { PageWrapper } from "pages/styled";
import React from "react";
import styled from "styled-components";
import { GraphResponsiveStyledCard } from "../component";
import UniqueWalletsGraph from "./UniqueWalletsGraph";
import HoldDurationGraph from "./HoldDurationGraph";
import WhaleGraph from "./WhaleGraph";
import TopMutualCollectionTable from "./TopMutualCollectionTable";
import CrossAppUsage from "./CrossAppUsageTable";
import TopOwners from "pages/Profile/components/TopOwners";
import TopOnwersFinancialProfileTable from "./TopOwnersFinancialProfileTable";
import TopTokensHeldByUsersTable from "./TopTokensHeldByUsersTable";
import axios from "axios";

import { useHoldDuration } from "data/yoots/holdDuration";
import { useParams } from "react-router-dom";
import { useAppState } from "Constexts/app.context";
import { useEffect } from "react";

export default function OwnersSection() {
  const refGraph: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const refUniqueWallets: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const refHoldDuration: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const refTable: React.RefObject<HTMLElement> =
    React.useRef<HTMLElement>(null);
  const [list, setlistData] = React.useState({});

  const { mainCollection, setMainCollection } = useAppState();

  let profileID: any;

  if (mainCollection === "aps") {
    profileID = "0x419e82D502f598Ca63d821D3bBD8dFEFAf9Bbc8D";
  } else if (mainCollection === "donsy") {
    profileID = "0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB";
  }

  const holdDuration = useHoldDuration(profileID);

  useEffect(() => {
    // holdDurationData();
  }, [mainCollection, setlistData]);

  return (
    <Wrapper>
      <GraphResponsiveStyledCard>
        <TopOnwersFinancialProfileTable />
      </GraphResponsiveStyledCard>

      <ResponsiveRow width={"100%"} gap="26px">
        <GraphResponsiveStyledCard ref={refUniqueWallets}>
          <UniqueWalletsGraph refGraph={refUniqueWallets} />
        </GraphResponsiveStyledCard>
        <GraphResponsiveStyledCard>
          <HoldDurationGraph data={holdDuration} refGraph={refHoldDuration}/>
        </GraphResponsiveStyledCard>
      </ResponsiveRow>

      {/* <GraphResponsiveStyledCard>
        <WhaleGraph refGraph={refGraph} />
      </GraphResponsiveStyledCard> */}

      <ResponsiveRow width={"100%"} gap="26px">
        <GraphResponsiveStyledCard ref={refTable}>
          <TopMutualCollectionTable />
        </GraphResponsiveStyledCard>
        <GraphResponsiveStyledCard ref={refGraph}>
          <TopTokensHeldByUsersTable refTable={refGraph} />
        </GraphResponsiveStyledCard>
      </ResponsiveRow>

      {/* <GraphResponsiveStyledCard>
        <CrossAppUsage />
      </GraphResponsiveStyledCard> */}
    </Wrapper>
  );
}

const Wrapper = styled.div``;
