import assets from "assets";
import { SquareIconButton } from "components/Button";
import { HorizontalDivider } from "components/Divider/inde";
import {
    SnapshotBrandLogoImageWrapper,
    GenericImageWrapper,
} from "components/Logo";
import QuestionHelper from "components/QuestionHelper";
import Row, { RowBetween } from "components/Row";
import { ResponsiveGraphHeader } from "pages/MarketOverview";
import React from "react";
import styled from "styled-components";

export default function TopHeaderDyanmicRightComponent({
    title,
    tooltip,
    rightComponent
}: {
    title: string;
    tooltip?: string;
    rightComponent?: React.ReactElement;
}) {
    return (
        <>
            <ResponsiveWrapper>
                <Row
                    alignItems={"center"}
                    alignContent={"center"}
                    justifyContent={"center"}
                >
                    <ResponsiveGraphHeader>{title}</ResponsiveGraphHeader>
                    {tooltip ? (
                        <QuestionHelper
                            text={tooltip}
                            iconSize="28px"
                            mobileIconSize="18px"
                        />
                    ) : (
                        <></>
                    )}
                </Row>
                {rightComponent}
            </ResponsiveWrapper>
            <HorizontalDivider />
        </>

    );
}


const ResponsiveWrapper = styled(RowBetween)`
  padding:15px 29px 15px 30px;
  position:relative;
  z-index: 100;
  
  ${({ theme }) => theme.mediaWidth.upToMedium`
      padding: 12px;
  `}
` 