import axios from "axios";
import Row, { AutoRow, ResponsiveRow, ResponsiveRowWrap } from "components/Row";
import React, { createRef, useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { OverviewProfileCard } from "components/DataCard";
import { PageWrapper } from "pages/styled";
import styled, { useTheme } from "styled-components";
import Column, { AutoColumn } from "components/Column";
import Spacer from "components/Spacer";
import { useProtocolChartData } from "store/protocol/hooks";
import { TEXT } from "theme/texts";
import QuestionHelper from "components/QuestionHelper";
import { ChevronRight } from "react-feather";
import { useNavigate } from "react-router-dom";
import { utils, ethers } from "ethers";
import { useCurrencyUnit } from "store/user/hooks";
import { formatAmount } from "utils/numbers";
import Tabview from "components/Tab";
import assets from "assets";
import { HorizontalDivider } from "components/Divider/inde";
import OverviewSection from "./overview";
import TradingDataSection from "./tradingData";
import OwnersSection from "./owners";
import ActivitySection from "./activity";
import InsightsSection from "./insights";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import ProfileHeader from "./ProfileHeader/ProfileHeader";
import LeaderboardSection from "./Leaderboard";

import { AppState } from "../../store";
import { useAppState } from "Constexts/app.context";
import SwitchBtn from "./ColoctionSwitch";
import CombindDataSection from "./CombaindData";
import { HideLarge, ShowLarge, ShowMedium } from "components/Hide";
import SwitchBtnM from "./ColoctionSwitch/SwitchBtnMobil";

const TABS = {
  OVERVIEW: "overview",
  TRADING_DATA: "trading_data",
  OWNERS: "owners",
  ACTIVITY: "activity",
  INSIGHTS: "insights",
  LEADERBOARD: "leaderboard",
};
const TabviewConfig: { label: string; value: string }[] = [
  {
    label: "Overview",
    value: TABS.OVERVIEW,
  },
//   {
//     label: "Trading Data",
//     value: TABS.TRADING_DATA,
//   },
//   {
//     label: "Owners",
//     value: TABS.OWNERS,
//   },
//   {
//     label: "Activity",
//     value: TABS.ACTIVITY,
//   },
//   {
//     label: "Leaderboard",
//     value: TABS.LEADERBOARD,
//   },
  // {
  //   label: "Insights",
  //   value: TABS.INSIGHTS,
  // },
];

const Combaind = () => {
  const theme = useTheme();
  const { isMobile } = useMatchBreakpoints();
  const navigate = useNavigate();
  const refGraph = createRef();
  const { currency } = useCurrencyUnit();
  const [selectedTab, setSelectedTab] = useState(TABS.OVERVIEW);

  const { mainCollection, setMainCollection } = useAppState();

  const { nftId } = useParams();
  let profileData;

  if (mainCollection === "aps") {
    profileData = {
      image:
        "https://i.seadn.io/gcs/files/35c90cbb8d9466adb1440400fda51d04.jpg?auto=format&dpr=1&w=256",
      name: "Polygon Ape",
      discription: "Welcome Back Polygon Ape: The Evolution Team !",

      contractAddress: "0x419e82D502f598Ca63d821D3bBD8dFEFAf9Bbc8D",
      erc_type: "ERC721",
      marketplace: {
        opensea: "https://opensea.io/collection/polygonapetheevolution",
        rarible: "https://rarible.com/polygonapetheevolution/items",
        magiceden:
          "https://magiceden.io/collections/polygon/polygonapetheevolution",
      },
      twitter: "https://twitter.com/0xPolygonApe",
      discord: "https://discord.com/invite/KMqbM8ePCy",
      website: "https://klekshun.com/",
    };
  } else if(mainCollection === "donsy"){
    profileData = {
      image:
        "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeihj4lvvllmkbhdcfsdpgawbnbssexbrvzwovmmeb67je3alvw4upe.ipfs.nftstorage.link/",
      name: "Donsy",
      discription: "Welcome Back Donsy Team !",
      contractAddress: "0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB",
      erc_type: "ERC721",
      marketplace: {
        opensea: "https://opensea.io/collection/donsy",
        rarible: "https://rarible.com/donsy/items",
        magiceden: "https://magiceden.io/collections/polygon/donsy",
      },
      twitter: "https://twitter.com/DonsyNFT",
      discord: "https://discord.com/invite/KMqbM8ePCy",
      website: "https://klekshun.com/",
    };
  }else{
    profileData = {
      image:
      "https://klekshun.com/_next/static/media/logo.4debae94.png",
    name: "Klekshun",
    discription: "Welcome Back Donsy Team !",
    contractAddress: "0x187778E70489bbD9c81de1F87FD6a9d2088CF1EB",
    erc_type: "ERC721",
    marketplace: {
      opensea: "https://opensea.io/Klekshun/created",
      // rarible: "https://rarible.com/donsy/items",
      // magiceden: "https://magiceden.io/collections/polygon/donsy",
    },
    twitter: "https://twitter.com/Klekshun",
    discord: "https://discord.com/invite/KMqbM8ePCy",
    website: "https://klekshun.com/",
  };

  }

  if (nftId === "dony") {
  } else {
  }
  console.log(nftId);

  function handleTabChange(value: string): void {
    setSelectedTab(value);
  }
  

  return (
    <Wrapper>
      <HorizontalDivider />
      
      <ProfileViewWrapper>
        
      <div className="hidepc">
        <SwitchBtnM />
        
      </div>
        {/* <TEXT.LargeHeader
          fontSize={"clamp(1.5rem,4vw,3rem)"}
          style={{ padding: "1rem 0" }}
        >
          {profileData?.discription}
        </TEXT.LargeHeader> */}
      


        <Column style={{ gap: "1rem" }}>
          <ProfileHeader profileData={profileData} />
          <Tabview
            data={TabviewConfig}
            value={selectedTab}
            onClick={handleTabChange}
          />
        </Column>
      </ProfileViewWrapper>
      <HorizontalDivider />
      <BodyWrapper>
        {(() => {
          switch (selectedTab) {
            case TABS.OVERVIEW:
              return <CombindDataSection />;
            case TABS.TRADING_DATA:
              return <TradingDataSection />;
            case TABS.OWNERS:
              return <OwnersSection />;
            case TABS.ACTIVITY:
              return <ActivitySection />;
            // case TABS.INSIGHTS:
            //   return <InsightsSection />;
            case TABS.LEADERBOARD:
              return <LeaderboardSection />;
            default:
              return <></>;
          }
        })()}
      </BodyWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;

  .hidepc{
  display: none;
}
${({ theme }) => theme.mediaWidth.upToSmall`
     .hidepc{
  display: flwx;
  `
}


`;

const ProfileViewWrapper = styled(PageWrapper)`

  padding-bottom: 0px;
  padding-top: 20px;

  ._graph {
    border: 1px solid ${(props) => props.theme.disabled};
  }
  ._profileNameLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
  ._volAndFloorGraphsCtr,
  ._salesAndOwnersGraphsCtr {
    overflow: hidden;
    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      flex-direction:column;
    `}
  }
`;

const BodyWrapper = styled(PageWrapper)`
  width: 100%;
  padding-top: 0;
`;


export default Combaind