import assets from "assets";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const BrandLogo = ({ hideNavOnLoc, footer }) => {
  const navigate = useNavigate();
  return (
    <BrandLogoCtr
      onClick={() => {
        navigate("/");
        hideNavOnLoc();
      }}
    >
    {
      footer?
      <BrandLogoImgF src={assets.logos.layerELogo} alt="brand-logo" />


      :
      <BrandLogoImg src={assets.logos.logok} alt="brand-logo" />



    }
    </BrandLogoCtr>
  );
};
const BrandLogoCtr = styled.div`
  display: grid;
  place-items: center;
  padding: 0.1rem;
  /* width: ${({footer})=> footer ? "auto" : "clamp(150px, 10vw, 200px)"}; */
  cursor: pointer;
`;
const BrandLogoImg = styled.img`
  max-width: 100%;
  object-fit: contain;
`;
const BrandLogoImgF = styled.img`
  width: 200px;
  object-fit: contain;
`;
export default BrandLogo;
