import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { Button as RebassButton, ButtonProps } from "rebass/styled-components";
import { darken } from "polished";

const Base = styled(RebassButton) <{
  padding?: string;
  width?: string;
  borderRadius?: string;
  altDisabledStyle?: boolean;
}>`
  padding: ${({ padding }) => (padding ? padding : "8px 16px")};
  width: ${({ width }) => (width ? width : "100%")};
  font-weight: 500;
  text-align: center;
  border-radius: 12px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;
  border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`;

export const SmallOptionButton = styled(Base) <{ active?: boolean }>`
  width: fit-content;
  border-radius: ${({ theme }) => theme.borderRadius.button};
  border: 1px solid ${({ theme }) => theme.borderActiveToggleBtn};
  min-width: 36px;
  font-size: 14px;
  background-color: ${({ active, theme }) =>
    active ? theme.btnSecondary : "transparent"};
  color: ${({ active, theme }) => (active ? theme.white : theme.white)};
  :hover {
    color: ${({ theme }) => theme.white};
  }
  height: 40px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
      height:fit-content;
      font-size: 12px;
      width: min-content;
  `}
`;

export const SquareIconButton = styled(Base) <{
  active?: boolean;
  backgroundColor?: string;
  showBorder?: boolean;
}>`
  width: fit-content;
  background-color: ${({ active, backgroundColor, theme }) =>
    active
      ? theme.btnSecondary
      : backgroundColor
        ? backgroundColor
        : theme.bgTop};
  color: ${({ active, theme }) => (active ? theme.body : theme.subBody)};
  border: 1px solid
    ${({ theme, showBorder }) =>
    showBorder === undefined || showBorder
      ? theme.borderActiveToggleBtn
      : "transparent"};
  border-radius: ${({ theme }) => theme.borderRadius.button};
  padding: 10px;
  :hover {
    opacity: 0.9;
  }
    ${({ theme }) => theme.mediaWidth.upToMedium`
      padding: 7.5px;
  `}
`;

export const PrimaryButton = styled(Base)`
  display: flex;
  gap: 9px;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  cursor: pointer;
  user-select: none;
  border: none;
  outline: none;
  background-color: ${({ theme }) => theme.btnPrimary};
  color: ${({ theme }) => theme.body};
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.05, theme.btnPrimary)};
  }

  :active {
    background-color: ${({ theme }) => darken(0.1, theme.btnPrimary)};
  }

  :disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => darken(0.1, theme.subBody)};
    cursor: auto;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
      padding: 0.6rem;
  `}
`;

export const SecondaryButton = styled(PrimaryButton) <{
  active?: boolean;
  borderRadius?: string;
}>`
white-space: nowrap;
color: #fff;
transition: all 0.3s ease;
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ theme, active }) =>
    active ? theme.btnSecondary : theme.black};
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.05, theme.btnSecondary)};
  }

  :active {
    background-color: ${({ theme }) => darken(0.1, theme.btnSecondary)};
  }
`;
